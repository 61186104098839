import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import PageContainer from "../../../components/PageContainer";
import { Grid } from "@mui/material";
import usePageApi from "../../../hooks/usePageApi";
import Dropdown from "../../../components/Dropdown";
import Field from "../../../components/Field";
import useLookup from "../../../hooks/useLookup";
import CDatePicker from "../../../components/DatePicker";
import usePageNav from "../../../hooks/usePageNav";
import ScreenProvider from "../../../containers/ScreenProvider";
import { hohInfoPayload } from "../../../services/HohInfo/apiPayload";
import dayjs from "dayjs";
import CoTextField from "../../../controllers/CoTextField";
import {
  maxLength,
  name,
  number,
  onlyAlphabet,
} from "../../../utils/normalizers";
import { yupResolver } from "@hookform/resolvers/yup";
import { onlyAlphabtes } from "../../../ValidationRules";
import * as yup from "yup";
import useLoader from "../../../hooks/useLoader";
import { showErrorToast } from "../../../utils/services/toast";
import { saveMember } from "../../../services/apiRequests";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import { useDispatch, useSelector } from "react-redux";
import useDynamicNav from "../../../hooks/useDynamicNav";

const YourInformation = ({ screenId }) => {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();
  const controlId = useSelector(selecttActiveContId);

  const { pageData, labels } = usePageApi({
    screenId,
    callRetrieveStatus: true,
  });

  const { getOptions } = useLookup("hoh");
  const userSchema = yup.object().shape({
    firstName: onlyAlphabtes("First Name"),
    lastName: onlyAlphabtes("Last Name"),
    DOB: yup
      .date()
      .typeError("Choose Date")
      .max(dayjs().format("YYYY/MM/DD"))
      .required(),
  });

  const { suffix, ethnicity, race } = useMemo(() => {
    return {
      suffix: getOptions("Suffix"),
      martialStatusOpts: getOptions("MartialStatus"),
      gender: getOptions("Gender"),
      ethnicity: getOptions("Ethnicity"),
      race: getOptions("Race"),
    };
  }, [getOptions]);

  const {
    watch,
    control,
    getValues,
    reset,
    //errors,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(userSchema),

    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      suffix: "",
      DOB: "",
      primaryRace: "",
      ethnicity: "",
      ...pageData,
    },
  });

  const onBack = () => {
    navigations.toPreferredLang();
  };

  const individualId = pageData?.[0]?.individualId;

  const onSave = async () => {
    const payload = {
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      middleName: getValues("middleName"),
      suffix: getValues("suffix"),
      gender: getValues("gender"),
      DOB: getValues("DOB"),
      ethnicity: getValues("ethnicity"),
      primaryRace: getValues("primaryRace"),
      dirtyStatus: individualId ? "UPDATE" : "INSERT",
      controlId: controlId,
      individualId: individualId,
      screenId: screenId,
    };
    try {
      showLoader();
      const res = await saveMember(hohInfoPayload(payload));
      if (res?.status === 200) {
        handleNext();
        dispatch({ type: "FETCH_HOH_INFO" });
        navigations.toAddressInformation();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const data = pageData?.[0];

  useEffect(() => {
    if (data) {
      reset({
        firstName: data?.clientContainer?.client?.personName?.firstName,
        lastName: data?.clientContainer?.client?.personName?.lastName,
        middleName: data?.clientContainer?.client?.personName?.middleName,
        suffix: data?.clientContainer?.client?.personName?.suffix,
        DOB: data?.clientContainer?.client?.dob
          ? dayjs(data?.clientContainer?.client?.dob)
          : "",
        primaryRace: data?.clientContainer?.client?.race?.primaryRace,
        ethnicity: data?.clientContainer?.client?.ethnicity,
      });
    }
  }, [data]);

  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="Your Information"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Grid container xs={12} lg={6} spacing={2}>
            <Grid item xs={12} lg={12}>
              <Field
                name="firstName"
                label="First Name"
                required
                error={errors?.firstName?.message}
                showError={errors?.firstName?.message}
              >
                <CoTextField
                  name="firstName"
                  normalizers={[onlyAlphabet, name, maxLength(20)]}
                  control={control}
                  placeholder="Enter First Name"
                />
              </Field>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Field name="middleName" label="Middle Name">
                <CoTextField
                  name="middleName"
                  normalizers={[onlyAlphabet, name, maxLength(12)]}
                  control={control}
                  placeholder="Enter Middle Name"
                />
              </Field>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Field
                name="lastName"
                label="Last Name"
                required
                error={errors?.lastName?.message}
                showError={errors?.lastName?.message}
              >
                <CoTextField
                  name="lastName"
                  normalizers={[onlyAlphabet, name, maxLength(24)]}
                  control={control}
                  placeholder="Enter Last Name"
                />
              </Field>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Field name="suffix" label="Suffix">
                <Dropdown
                  {...register("suffix")}
                  name="suffix"
                  placeholder="Select Suffix"
                  control={control}
                  options={suffix}
                />
              </Field>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Field
                name="DOB"
                label="Date of Birth"
                error={errors.DOB?.message}
                showError={errors.DOB?.message}
                required
              >
                <CDatePicker
                  name="DOB"
                  disableFuture
                  control={control}
                  placeholder="Select Date(MM/DD/YYYY)"
                />
              </Field>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Field name="primaryRace" label="Race">
                <Dropdown
                  name="primaryRace"
                  placeholder="Select Race"
                  control={control}
                  options={race}
                />
              </Field>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Field name="ethnicity" label="Ethnicity">
                <Dropdown
                  name="ethnicity"
                  placeholder="Select Ethnicity"
                  control={control}
                  options={ethnicity}
                />
              </Field>
            </Grid>
          </Grid>
        </PageContainer>
      </Box>
    </ScreenProvider>
  );
};
export default YourInformation;
