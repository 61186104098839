import React, { useEffect, useState } from "react";
import SelectableView from "../../../components/SelectableView";
import MemberMenuButton from "../../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../../hooks/usePageApi";
import ScreenProvider from "../../../containers/ScreenProvider";
// import MemberForm
import { filter, map } from "lodash";
import MemberDetails from "./MemberDetails";
import PageContainer from "../../../components/PageContainer";
import usePageNav from "../../../hooks/usePageNav";
import useDynamicNav from "../../../hooks/useDynamicNav";
import AddMember from ".././HouseHoldMember/AddHouseHoldMember";
import HttpClient from "../../../utils/HttpClient";
import { Box } from "@mui/material";
import AddHouseMember from "./AddMember";

const mockData = [
  {
    name: "sanjaye",
    age: "25",
    id: 2,
  },
];
export default function HouseHoldMember({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const [data, setData] = useState([]);
  const [showAddMember, setAddMember] = useState(false);
  const [showEditMember, setEditMember] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState("");
  const { pageData, labels, retrieveJson } = usePageApi({
    screenId,
    callRetrieveStatus: true,
  });

  const { pageData: selectedPrograms } = usePageApi(
    {
      screenDefn: "/dashboard/consumer-app/intake-app/assistance/progSelect",
    },
    {
      getLabels: false,
    }
  );

  const choosenPrograms = map(selectedPrograms, ({ programSelected = {} }) => {
    return programSelected?.programName;
  });

  useEffect(() => {
    if (pageData) {
      setData(pageData);
      console.log(pageData, "pd");
    }
  }, [pageData]);

  const toggleAddMember = () => {
    setAddMember(!showAddMember);
    retrieveJson();
  };

  const toggleEditMember = () => {
    setEditMember(!showEditMember);
    retrieveJson();
  };

  const onBack = () => {
    navigations.toRequestedProgram();
  };

  const onNext = () => {
    navigations.toFamilySurvey();
    handleNext();
  };

  const filteredData = filter(data, (item) => item);

  const onAddMember = () => {
    retrieveJson();
  };

  if (showEditMember) {
    return (
      <Box sx={{ p: "1.5rem 0.5rem", backgroundColor: "#fff" }}>
        <AddHouseMember
          onAddMember={onAddMember}
          selected={selectedMembers}
          // choosenPrograms={choosenPrograms}
          // open={showEditMember}
          toggle={toggleEditMember}
          screenId={screenId}
          editData
        />
      </Box>
    );
  }

  return (
    <ScreenProvider labels={labels}>
      {/* {showAddMember && (
        <MemberForm
          onAddMember={onAddMember}
          choosenPrograms={choosenPrograms}
          open={showAddMember}
          toggle={toggleAddMember}
        />
      )} */}
      {showAddMember ? (
        <Box sx={{ p: "1.5rem 0.5rem", backgroundColor: "#fff" }}>
          <AddHouseMember
            onAddMember={onAddMember}
            toggle={toggleAddMember}
            choosenPrograms={choosenPrograms}
            screenId={screenId}
          />
        </Box>
      ) : (
        <PageContainer title="Household Info" onBack={onBack} onNext={onNext}>
          <SelectableView
            data={data}
            title="Members"
            showAdd={true}
            showEdit={true}
            showDelete={true}
            addDataMsg="Add member"
            noDataMsg="No household member found!"
            onAdd={() => toggleAddMember()}
            // onAdd={() => navigations.toAddHoh()}
            onEdit={() => toggleEditMember()}
            getId={(params) => {
              return params?.individualId;
            }}
            getAge={(params) => {
              return params?.clientContainer?.client?.dob;
            }}
            getName={(params) => {
              const { firstName, lastName } =
                params?.clientContainer?.client?.personName ?? {};
              return `${firstName} ${lastName}`;
            }}
            renderMenuButton={(params, options) => {
              return (
                <MemberMenuButton
                  member={params}
                  options={options}
                  // genderLkp={lkpFlatten?.Gender}
                />
              );
            }}
          >
            {(selected) => {
              setSelectedMembers(selected);
              return (
                <>
                  <MemberDetails
                    selected={selected}
                    pageData={pageData}
                    labels={labels}
                  />
                  {/* {showEditMember && (
                <AddMember
                  onAddMember={onAddMember}
                  selected={selected}
                  // choosenPrograms={choosenPrograms}
                  open={showEditMember}
                  toggle={toggleEditMember}
                  screenId={screenId}
                  editData
                />
              )} */}

                  {/* {showEditMember && (
                    <AddHouseMember
                      onAddMember={onAddMember}
                      selected={selected}
                      // choosenPrograms={choosenPrograms}
                      open={showEditMember}
                      toggle={toggleEditMember}
                      screenId={screenId}
                      editData
                    />
                  )} */}
                </>
              );
            }}
          </SelectableView>
        </PageContainer>
      )}

      {/* {showAddMember && (
        <AddMember
          onAddMember={onAddMember}
          // choosenPrograms={choosenPrograms}
          open={showAddMember}
          toggle={toggleAddMember}
          choosenPrograms={choosenPrograms}
          screenId={screenId}
        />
      )} */}
    </ScreenProvider>
  );
}
