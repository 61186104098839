import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, AppBar, Toolbar, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
// import Kconnect from "../../../assets/images/logo.png";
import Kconnect from "../../../assets/images/KcaresLogo.svg";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CustomMenuList from "./CustomMenuList";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import usePageNav from "../../../hooks/usePageNav";
import { useDispatch, useSelector } from "react-redux";
import { revokeAuthToken } from "../../../store/slices/authSlice";
import { getAuthToken } from "../../../store/selectors/authSelector";

function NavBar(props) {
  const tokenData = useSelector(getAuthToken);
  const dispatch = useDispatch();
  const [anchorNav, setAnchorNav] = useState(null);
  const [userMenu, setUserMenu] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  const { navigations } = usePageNav();
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const open = Boolean(anchorNav);
  const openMenu = (event) => {
    setAnchorNav(event.currentTarget);
  };

  const goLogin = () => {
    setIsLogin(true);
    navigations.toLogin();
  };

  useEffect(() => {
    if (tokenData) {
      setIsLogin(true);
    }
  }, [tokenData]);

  const openUser = Boolean(userMenu);
  const openUserMenu = (event) => {
    setUserMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorNav(null);
  };

  const closeMenuUser = () => {
    setUserMenu(null);
  };

  const handleLogin = () => {
    setIsLogin(true);
    navigations.toLogin();
    setAnchorNav(null);
    setUserMenu(null);
  };

  const handleLogout = () => {
    setIsLogin(false);
    dispatch(revokeAuthToken());
    navigations.toLandingPage();
    setAnchorNav(null);
    setUserMenu(null);
  };

  const mainMenuItems = [
    { text: "Explore Benefits" },
    { text: "Program" },
    { text: "Help" },
    { text: "Language" },
    {
      text: "Sign In",
      onClick: handleLogin,

      // component: () => (
      //   <Button variant="contained" fullWidth onClick={() => setIsLogin(true)}>
      //     Sign In
      //   </Button>
      // ),
    },
  ];

  function stringAvatar(name) {
    return {
      children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
    };
  }

  const userMenuItems = [
    { text: "Profile" },
    { text: "Account" },
    { text: "Dashboard" },
    { text: "Logout", onClick: handleLogout },
  ];

  const mainMenu = (
    <CustomMenuList
      anchorEl={anchorNav}
      open={open}
      onClose={closeMenu}
      menuItems={mainMenuItems}
    />
  );

  const userMenuComponent = (
    <CustomMenuList
      anchorEl={userMenu}
      open={openUser}
      onClose={closeMenuUser}
      menuItems={userMenuItems}
      sx={{ display: { xs: "none", md: "flex" } }}
    />
  );

  useMemo(() => {
    if (isMdScreen) {
      setAnchorNav(null);
    }
  }, [isMdScreen]);

  return (
    <AppBar position="static" style={{ backgroundColor: "#20374B" }}>
      <Box sx={{paddingBottom:1}}>
      <Toolbar sx={{alignItems:"flex-end"}}>
        <Box sx={{ flexGrow: 1,alignItems:"flex-end",display:"flex" }}>
          <IconButton
            onClick={() => navigations.toDashboard()}
            size="large"
            edge="start"
            color="inherit"
            sx={{paddingBottom:"6px"}}
          >
            <img src={Kconnect} />
          </IconButton>
        </Box>

        <Box
          gap={4}
          sx={{ display: { xs: "none", md: "flex" }, alignItems: "flex-end" }}
        >
          <Button color="inherit" endIcon={<ArrowDropDownIcon />}>
            Explore Benefits
          </Button>
          <Button color="inherit" endIcon={<ArrowDropDownIcon />}>
            Program
          </Button>
          <Button color="inherit" endIcon={<ArrowDropDownIcon />}>
            Help
          </Button>

          <Button color="inherit">Language</Button>
          {isLogin ? (
            <>
              <Badge color="info" badgeContent={0} showZero sx={{padding:"6px 8px"}}>
                <NotificationsIcon />
              </Badge>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                onClick={openUserMenu}
                id="fade-profile"
                aria-controls={openUser ? "fade-user-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openUser ? "true" : undefined}
                sx={{padding:"6px 8px"}}
              >
                <Avatar {...stringAvatar(tokenData?.name)} alt="profile" />
              </IconButton>
            </>
          ) : (
            <Button variant="contained" onClick={() => goLogin()}>
              Login
            </Button>
          )}
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={openMenu}
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MenuIcon />
          </IconButton>
          {mainMenu}
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          {userMenuComponent}
        </Box>
      </Toolbar>
      </Box>
    </AppBar>
  );
}

NavBar.propTypes = {};

export default NavBar;
