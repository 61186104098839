import HttpClient, { smartyStreets } from "../utils/HttpClient";
import {
  SMARTY_STREET,
  VENDOR_SEARCH,
  RELATIONSHIP_MEMBERDETAILS,
  FILE_UPLOADER,
  GET_SUMMARY,
  SUBMITEDCASE,
  SIGNSAVE,
  PDFGEN,
  CASE_INITIATE,
  APPLY_BENEFITS,
  POST_UPLOAD_FILES,
  GET_UPLOAD_FILES,
  MEMBER,
  REAL_TIME_CASE_STATUS,
  FETCH_NAVIGATION,
} from "./apiUrls";
import {
  institutionPayload,
  smartyPayload,
  relationshipMember,
  fileUploader,
  getControlID,
  applyBenefits,
  documentUploadPayload,
} from "./apiPayload";
import { format } from "../utils/helpers";

export const postInstitution = (data = {}) => {
  return HttpClient.post(format(VENDOR_SEARCH), institutionPayload(data));
};
export const getCaseInitiator = () => {
  return HttpClient.post(format(CASE_INITIATE), getControlID());
};
export const applyConsumerBenefits = (customeId, benefits) => {
  return HttpClient.post(
    format(APPLY_BENEFITS, customeId),
    applyBenefits(customeId, benefits)
  );
};
export const postSmarty = (data = {}) => {
  return smartyStreets.post(format(SMARTY_STREET), smartyPayload(data));
};
export const reationShipMemberdetails = (
  individualId = "",
  controlId = "",
  gender = ""
) => {
  return HttpClient.post(
    format(RELATIONSHIP_MEMBERDETAILS, individualId),
    relationshipMember(gender, controlId)
  );
};
export const fileUplaod = (
  caseID,
  file,
  documentTypeCd,
  custIndiviualId,
  lookupShortVal
) => {
  return HttpClient.post(
    format(FILE_UPLOADER, caseID),
    fileUploader(file, documentTypeCd, custIndiviualId, lookupShortVal)
  );
};

export const getSummaryData = (controlId) => {
  return HttpClient.get(format(GET_SUMMARY, controlId));
};

export const getSubmitCase = (controlId) => {
  return HttpClient.post(format(SUBMITEDCASE, controlId));
};

export const getSignSave = (controlId, data = {}) => {
  return HttpClient({
    url: `/public/consumer/jsonSave/${controlId}/168`,
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
    },
    data: data,
  });

  // .post(format(SIGNSAVE, 1060), data);
};

export const getPdfGen = (controlId) => {
  return HttpClient.post(
    format(PDFGEN, controlId),
    {},
    {
      responseType: "blob",
    }
  );
};
export const documentUpload = (initialState = {}) => {
  return HttpClient.post(
    `${process.env.REACT_APP_DMS_URL}/docControl/documentDetails/upload`,
    documentUploadPayload(initialState),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const downloadDocument = (documentId, sourceCaseId) => {
  return HttpClient.post(
    `${process.env.REACT_APP_DMS_URL}/docControl/downloadDocumentBy/${documentId}/${sourceCaseId}/KCARES`,
    {},
    {
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "access-control-allow-methods": "PUT, GET, HEAD, POST, DELETE, OPTIONS",
      },
    }
  );
};
export const getDocument = (controlId) => {
  return HttpClient.get(
    `${process.env.REACT_APP_DMS_URL}/docControl/getDocuments/${controlId}/KCARES`
  );
};
export const getIndividualDocument = (sourceCaseId) => {
  return HttpClient.get(
    `${process.env.REACT_APP_DMS_URL}/docControl/getDocumentsByCaseId/${sourceCaseId}/KCARES`
  );
};

export const saveMember = (data) => {
  return HttpClient.post(MEMBER, data);
};
export const realTimeCaseStatus = (data) => {
  return HttpClient.get(format(REAL_TIME_CASE_STATUS, data));
};

export const fetchNavReq = (data) => {
  return HttpClient.post(FETCH_NAVIGATION, data);
};
