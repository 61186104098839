import React, { useEffect, useState } from "react";
import ScreenProvider from "../../containers/ScreenProvider";
import { Box, Grid, Stack } from "@mui/material";
import PageContainer from "../../components/PageContainer";
import YesNoOption from "../../components/YesNoOption";
import usePageApi from "../../hooks/usePageApi";
import Field from "../../components/Field";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CCheckBox from "../../components/checkBox";
import CoTextField from "../../controllers/CoTextField";
import CDatePicker from "../../components/DatePicker";
import { maxLength, number } from "../../utils/normalizers";
import { map, rest } from "lodash";
import usePageNav from "../../hooks/usePageNav";
import useLoader from "../../hooks/useLoader";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { mandatory, onlyAlphabtes } from "../../ValidationRules";
import useDynamicNav from "../../hooks/useDynamicNav";

const FamilySurvey = ({ screenId }) => {
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId,
    // screenDefn: "/application/household-details/family-survey",
    callRetrieveStatus: true,
  });
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [members, setMembers] = useState([]);
  const { handleNext } = useDynamicNav({ screenId });

  const { pageData: memberPageDate } = usePageApi({
    screenId: 206,
    callRetrieveStatus: true,
  });

  const userSchema = yup.object().shape({
    atleast6Months: mandatory("Please choose any option"),
    atleast12Months: mandatory("Please choose any option"),
  });

  const {
    control,
    watch,
    setValue,
    reset,
    //errors,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(userSchema),

    defaultValues: {
      pregnant: [],
      atleast6Months: null,
      atleast12Months: null,
    },
  });

  useEffect(() => {
    if (pageData) {
      const defaultValues = {
        pregnant: map(pageData.pregnant, (item) =>
          typeof item === "object"
            ? {
                expectedChild: item?.expectedChild ?? "",
                dueDate: item?.dueDate ? dayjs(item.dueDate) : null,
              }
            : item
        ),
        atleast6Months: pageData?.atleast6Months ?? null,
        atleast12Months: pageData?.atleast12Months ?? null,
        atLeast12Months: map(pageData?.atLeast12Months, (item) => item),
      };
      reset(defaultValues);
    }
  }, [pageData, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "pregnant",
  });

  const onSave = async (value) => {
    try {
      showLoader();

      const res = await saveJson(value);
      if (res?.status === 200) {
        navigations.toIncomInfo();
        handleNext();
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const memberList = () => {
    const data = map(memberPageDate, (value) => {
      return {
        label: `${value?.clientContainer?.client?.personName?.firstName} ${value?.clientContainer?.client?.personName?.lastName}`,
        value: value?.individualId,
      };
    });
    return data;
  };

  useEffect(() => {
    const data = memberList();
    setMembers(data);
  }, [memberPageDate]);

  const onBack = () => {
    navigations.toHouseHoldMember();
  };

  const atLeast6Months = watch("atleast6Months") === "Y";
  const atLeast12Months = watch("atleast12Months") === "Y";

  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="Answer these questions about Parents & Children in your household."
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Stack
            rowGap={2}
            sx={{
              minWidth: "15.6rem",
              maxWidth: "30rem",
              paddingTop: "0.5rem",
            }}
          >
            <Grid container>
              <Grid
                item
                sx={{ gap: "2rem", display: "flex", flexDirection: "column" }}
              >
                <Field
                  name="atleast6Months"
                  label="Is anyone in your household pregnant now or were they in the last 6 months?"
                  required
                  error={errors?.atleast6Months?.message}
                  showError={errors?.atleast6Months?.message}
                >
                  <Controller
                    name="atleast6Months"
                    control={control}
                    render={({ field }) => <YesNoOption {...field} />}
                  />
                </Field>
                {atLeast6Months && (
                  <Field name="wasPregnant" label="Who is/was Pregnant?">
                    {map(members, (value, index) => (
                      <Box key={index}>
                        <CCheckBox
                          name={`pregnant.${index}`}
                          control={control}
                          label={value.label}
                        />
                        {watch(`pregnant.${index}`) && (
                          <Grid item sx={{ display: "flex" }}>
                            <Field label="Number of Children">
                              <CoTextField
                                sx={{ width: "6rem" }}
                                normalizers={[number, maxLength(2)]}
                                control={control}
                                name={`pregnant.${index}.expectedChild`}
                              />
                            </Field>
                            <Field label="Enter Due Date">
                              <CDatePicker
                                disablePast
                                name={`pregnant.${index}.dueDate`}
                                control={control}
                                placeholder="Select Date(MM/DD/YYYY)"
                              />
                            </Field>
                          </Grid>
                        )}
                      </Box>
                    ))}
                  </Field>
                )}
                <Field
                  name="atleast12Months"
                  label="Is anyone in the household currently breastfeeding a baby that is less than 12 months old?"
                  required
                  error={errors?.atleast12Months?.message}
                  showError={errors?.atleast12Months?.message}
                >
                  <Controller
                    name="atleast12Months"
                    control={control}
                    render={({ field }) => <YesNoOption {...field} />}
                  />
                </Field>

                {atLeast12Months && (
                  <Field
                    name="currentlyBreastfeeding"
                    label="Who is currently breastfeeding?"
                  >
                    {map(members, (value, index) => (
                      <Box key={value?.id}>
                        <CCheckBox
                          name={`atLeast12Months.${index}`}
                          control={control}
                          label={value.label}
                        />
                      </Box>
                    ))}
                  </Field>
                )}
              </Grid>
            </Grid>
          </Stack>
        </PageContainer>
      </Box>
    </ScreenProvider>
  );
};

export default FamilySurvey;
