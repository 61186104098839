import React from "react";
import { createContext, useMemo, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import HttpClient from "./utils/HttpClient";
import PrivateRoute from "./containers/PrivateRoute";
import Login from "./pages/Login";
import Layout from "./containers/Layout";
import PreferredLang from "./pages/YourInfo/PreferredLang";
import AddressInfo from "./pages/YourInfo/AddressInfo";
import ContactInfo from "./pages/YourInfo/ContactInfo";
import { useSelector } from "react-redux";
import { getAuthToken } from "./store/selectors/authSelector";
import Registration from "./pages/Registration";
import LandingPage from "./pages/LandingPage";
import ApplicationCont from "./containers/ApplicationCont";
import Dashboard from "./pages/Dashboard";
import YourInformation from "./pages/YourInfo/YourInformation";
import AppSubmit from "./pages/AppSubmit";
import RequestedProgram from "./pages/MemberInfo/RequestedProgram";
import FamilySurvey from "./pages/HouseHoldDetails";
import HouseHoldMember from "./pages/MemberInfo/HouseHoldMember";
import WicProgram from "./pages/WicProgram";
import Income from "./pages/Income";
import Employment from "./pages/Income/Employment";
import AdditionalIncome from "./pages/Income/AdditionalIncome";
import Signature from "./pages/Signature";
import CitizenshipDetails from "./pages/CitizenshipDetials";
import SelfEmployement from "./pages/Income/SelfEmployement";
import DocumentUpload from "./pages/DocumentUpload";
import CaseSummary from "./pages/CaseSummary";

export const PageContext = createContext();

function Empty({ title }) {
  return <div>{title}</div>;
}

function App() {
  const [currentPage, setCurrentPage] = useState("Home page");
  const token = useSelector(getAuthToken);
  const [expandSideMenu, setExpandSideMenu] = useState(true);
  const [screenId, setScreenId] = useState(-1);

  useMemo(() => {
    HttpClient.interceptors.request.handlers = [];
    HttpClient.interceptors.request.use(
      (config) => {
        if (token && token?.sub) {
          config.headers = {
            ...config.headers,
            uniqueid: token?.sub,
            uid: "localUser",
            role: "cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN",
            Accept: "application/json",
          };
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token]);

  return (
    <div className="App">
      <PageContext.Provider
        value={{
          currentPage,
          setCurrentPage,
          expandSideMenu,
          setExpandSideMenu,
          screenId,
          setScreenId,
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="landingPage" />} />
          <Route path="landingPage" element={<LandingPage />} />
          <Route path="login" element={<Login />} />
          <Route path="registration" element={<Registration />} />
          <Route
            path=""
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="application" element={<ApplicationCont />}>
              <Route path="aboutMe" element={<Outlet />}>
                <Route
                  path="preferred-lang"
                  element={<PreferredLang screenId={200} />}
                />
                <Route
                  path="your-information"
                  element={<YourInformation screenId={201} />}
                />
                <Route
                  path="currentAddress"
                  element={<AddressInfo screenId={202} />}
                />
                <Route
                  path="contact-info"
                  element={<ContactInfo screenId={203} />}
                />
                <Route
                  path="citizenship-details"
                  element={<CitizenshipDetails screenId={204} />}
                />
              </Route>
              <Route path="memberInfo" element={<Outlet />}>
                <Route
                  path="requested-program"
                  element={<RequestedProgram screenId={205} />}
                />
                <Route
                  path="household-member"
                  element={<HouseHoldMember screenId={206} />}
                />
              </Route>
              <Route path="household-details" element={<Outlet />}>
                <Route
                  path="family-survey"
                  element={<FamilySurvey screenId={207} />}
                />
              </Route>
              <Route path="incomeInfo" element={<Outlet />}>
                <Route path="income" element={<Income screenId={208} />} />
                <Route
                  path="employment"
                  element={<Employment screenId={209} />}
                />
                <Route
                  path="selfemployment"
                  element={<SelfEmployement screenId={210} />}
                />
                <Route
                  path="additionalincome"
                  element={<AdditionalIncome screenId={211} />}
                />
              </Route>
              <Route path="programinfo" element={<Outlet />}>
                <Route
                  path="wicprogram"
                  element={<WicProgram screenId={212} />}
                />
              </Route>
              <Route path="fileupload" element={<Outlet />}>
                <Route
                  path="document-upload"
                  element={<DocumentUpload screenId={213} />}
                />
              </Route>
              <Route path="submission" element={<Outlet />}>
                <Route
                  path="summary"
                  element={<CaseSummary screenId={214} />}
                />
                <Route
                  path="signature"
                  element={<Signature screenId={215} />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </PageContext.Provider>
    </div>
  );
}

export default App;
