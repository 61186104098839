import { Dashboard } from "@mui/icons-material";

const LookUpPayload = {
  PreferredLanguage: [{ lookupTypeCd: "PrimaryLanguage" }],
  addressInfo: [{ lookupTypeCd: "State" }],
  raceDisability: [{ lookupTypeCd: "Race" }, { lookupTypeCd: "Ethnicity" }],
  hoh: [
    { lookupTypeCd: "Suffix" },
    { lookupTypeCd: "MartialStatus" },
    { lookupTypeCd: "Gender" },
    { lookupTypeCd: "Race" },
    { lookupTypeCd: "Ethnicity" },
  ],
  Citizenship: [
    { lookupTypeCd: "Citizenship" },
    { lookupTypeCd: "CitizenshipVerification" },
    { lookupTypeCd: "CountryOfOrigin" },
    { lookupTypeCd: "VoterRegisteration" },
    { lookupTypeCd: "INSStatus" },
    { lookupTypeCd: "RefugeeResettlementAgency" },
    { lookupTypeCd: "YesNo" },
    { lookupTypeCd: "Gender" },
    { lookupTypeCd: "Documents" },
  ],
  StudentInfo: [
    { lookupTypeCd: "StudentStatusCode" },
    { lookupTypeCd: "GoodStandingSchoolAttendance" },
    { lookupTypeCd: "EducationalLevel" },
    { lookupTypeCd: "HighestGrade" },
    { lookupTypeCd: "State" },
  ],
  CourtOrder: [
    { lookupTypeCd: "Frequency" },
    { lookupTypeCd: "ChildSpousalExpense" },
    { lookupTypeCd: "Legal" },
  ],
  MedicalExpenses: [
    { lookupTypeCd: "ServiceProvided" },
    { lookupTypeCd: "MedicalExpenseType" },
    { lookupTypeCd: "Frequency" },
    { lookupTypeCd: "Medical" },
  ],
  contactInfo: [
    { lookupTypeCd: "CommunicationMode" },
    { lookupTypeCd: "PhoneType" },
    { lookupTypeCd: "PrimaryLanguage" },
  ],
  RelationshipInfo: [
    { lookupTypeCd: "IndividualRelationship" },
    { lookupTypeCd: "Gender" },
  ],
  assetsLookUp: [
    { lookupTypeCd: "AUTransfereeRelationship" },
    { lookupTypeCd: "AddAssetsAssetTypeParent" },
    { lookupTypeCd: "AddAssetsAssetTypeRealEstateProperties" },
    { lookupTypeCd: "EAasset" },
    { lookupTypeCd: "EAgoodcause" },
    { lookupTypeCd: "Gender" },
    { lookupTypeCd: "Insurance" },
    { lookupTypeCd: "LiquidAssetType" },
    { lookupTypeCd: "PropertyTypeOther" },
    { lookupTypeCd: "RealPropertyUse" },
    { lookupTypeCd: "ReasonForTransfer" },
    { lookupTypeCd: "State" },
    { lookupTypeCd: "TransferOfAssetType" },
    { lookupTypeCd: "UndueHardshipReason" },
    { lookupTypeCd: "VehicleType" },
    { lookupTypeCd: "VehicleUse" },
    { lookupTypeCd: "Assets" },
  ],
  fileUpload: [
    { lookupTypeCd: "Documents" },
    { lookupTypeCd: "Income" },
    { lookupTypeCd: "Agreement" },
    { lookupTypeCd: "Assets" },
    { lookupTypeCd: "Applications" },
    { lookupTypeCd: "Clearances" },
    { lookupTypeCd: "Disability Benefits Advocacy" },
    { lookupTypeCd: "Expenses" },
    { lookupTypeCd: "Fiscal" },
    { lookupTypeCd: "Identity" },
    { lookupTypeCd: "Legal" },
    { lookupTypeCd: "LTC Forms" },
    { lookupTypeCd: "MCP Special Programs" },
    { lookupTypeCd: "Medical" },
    { lookupTypeCd: "Quality Control" },
    { lookupTypeCd: "Redeterminations" },
    { lookupTypeCd: "Referrals" },
    { lookupTypeCd: "Verifications" },
    { lookupTypeCd: "Work Program" },
  ],
  caseSummary: [
    { lookupTypeCd: "Gender" },
    { lookupTypeCd: "MartialStatus" },
    { lookupTypeCd: "Race" },
    { lookupTypeCd: "Ethnicity" },
    { lookupTypeCd: "IndividualRelationship" },
    { lookupTypeCd: "Citizenship" },
    { lookupTypeCd: "AuthRepRelation" },
    { lookupTypeCd: "Frequency" },
    { lookupTypeCd: "MedicalExpenseType" },
    { lookupTypeCd: "ServiceProvided" },
    { lookupTypeCd: "YesNo" },
    { lookupTypeCd: "ChildSpousalExpense" },
    { lookupTypeCd: "AppliedUnearnedIncomeType" },
    { lookupTypeCd: "AppliedUnearnedIcomeStatus" },
  ],
  AuthRep: [
    { lookupTypeCd: "AuthRepRelation" },
    { lookupTypeCd: "State" },
    { lookupTypeCd: "AuthRepAccessType" },
    { lookupTypeCd: "Program" },
    { lookupTypeCd: "AuthRepTypeCd" },
  ],
  EarnedFrequency: [
    { lookupTypeCd: "EarningType" },
    { lookupTypeCd: "Frequency" },
    { lookupTypeCd: "PhoneType" },
    { lookupTypeCd: "State" },
    { lookupTypeCd: "Gender" },
    { lookupTypeCd: "Income" },
  ],
  hohInquiry: [{ lookupTypeCd: "CPFelonyConvictionType" }],
  memberSummary: [
    { lookupTypeCd: "Gender" },
    { lookupTypeCd: "MartialStatus" },
    { lookupTypeCd: "Race" },
    { lookupTypeCd: "Ethnicity" },
    { lookupTypeCd: "State" },
    { lookupTypeCd: "YesNo" },
  ],
  unearnedIncome: [
    { lookupTypeCd: "UnearnedIncomeType" },
    { lookupTypeCd: "CashAssistance" },
    { lookupTypeCd: "BenefitsPensions" },
    { lookupTypeCd: "CompensationPayments" },
    { lookupTypeCd: "SupportAlimony" },
    { lookupTypeCd: "ContributionsInKind" },
    { lookupTypeCd: "VendorPayments" },
    { lookupTypeCd: "Loans" },
    { lookupTypeCd: "EducationalAssistance" },
    { lookupTypeCd: "ReturnOnInvestments" },
    { lookupTypeCd: "FosterCareAdoption" },
    { lookupTypeCd: "NeedsBasedAssistance" },
    { lookupTypeCd: "PublicAssistance" },
    { lookupTypeCd: "Relocation" },
    { lookupTypeCd: "ShelterRelated" },
    { lookupTypeCd: "ProjectIndependence" },
    { lookupTypeCd: "LumpSum" },
    { lookupTypeCd: "VolunteerRelated" },
    { lookupTypeCd: "VariableInfrequent" },
    { lookupTypeCd: "OtherUI" },
    { lookupTypeCd: "CompensationPayments" },
    { lookupTypeCd: "SocialSecurity" },
    { lookupTypeCd: "Frequency" },
    { lookupTypeCd: "AppliedUnearnedIcomeStatus" },
    { lookupTypeCd: "AppliedUnearnedIncomeType" },
    { lookupTypeCd: "appliedunearnedassettype" },
    { lookupTypeCd: "Income" },
  ],
  ShelterUtility: [
    { lookupTypeCd: "Expenses" },
    { lookupTypeCd: "Fiscal" },
    { lookupTypeCd: "Medical" },
  ],
  Dashboard: [
    { lookupTypeCd: "Gender" },
    { lookupTypeCd: "IndividualRelationship" },
    { lookupTypeCd: "CaseStatusCode" },
  ],
  TransferAssets: [
    { lookupTypeCd: "TransferOfAssetType" },
    { lookupTypeCd: "ReasonForTransfer" },
    { lookupTypeCd: "AUTransfereeRelationship" },
    { lookupTypeCd: "UndueHardshipReason" },
  ],
  AmIEligible: [
    { lookupTypeCd: "Citizenship" },
    { lookupTypeCd: "Suffix" },
    { lookupTypeCd: "State" },
    { lookupTypeCd: "RelationshipType" },
    { lookupTypeCd: "County" },
  ],
  Roles: [{ lookupTypeCd: "Roles_Cp" }],
  SelfEmployement: [
    { lookupTypeCd: "Type Of Work" },
    { lookupTypeCd: "Income" },
  ],
  AdditionalIncome: [
    { lookupTypeCd: "Frequency" },
    {
      lookupTypeCd: "Income Type",
    },
  ],
  Employment: [{ lookupTypeCd: "Frequency" }, { lookupTypeCd: "Income" }],
  Citizenship: [{ lookupTypeCd: "Citizenship" }],
};

export default LookUpPayload;
