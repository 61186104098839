import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../../components/PageContainer";
import { Box, Grid, Stack, Typography } from "@mui/material";
import Dropdown from "../../../components/Dropdown";
import Field from "../../../components/Field";
import { useForm } from "react-hook-form";
import useLookup from "../../../hooks/useLookup";
import usePageApi from "../../../hooks/usePageApi";
import FieldTitle from "../../../components/FieldTitle";
import usePageNav from "../../../hooks/usePageNav";
import ScreenProvider from "../../../containers/ScreenProvider";
import useLoader from "../../../hooks/useLoader";
import { showErrorToast } from "../../../utils/services/toast";
import useDynamicNav from "../../../hooks/useDynamicNav";
import * as yup from "yup";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import { useSelector } from "react-redux";
function PreferredLang({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const controlId = useSelector(selecttActiveContId);
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: screenId,
    callRetrieveStatus: true,
  });

  const [showLoader, hideLoader] = useLoader();
  const {
    watch,
    control,
    getValues,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      languagetoSpeak: "",
      languagetoRead: "",
      applicationLanguage: "",
      ...pageData,
    },
  });
  const { getOptions } = useLookup("PreferredLanguage");
  const languageOpts = getOptions("PrimaryLanguage");

  const data = pageData?.preflangInfo?.[0];

  useEffect(() => {
    if (data) {
      setValue("languagetoRead", data.languagetoRead);
      setValue("languagetoSpeak", data.languagetoSpeak);
      setValue("applicationLanguage", data.applicationLanguage);
    }
  }, [data]);

  const onSave = async () => {
    const payload = {
      preflangInfo: [
        {
          dirtyStatus: "INSERT",
          screenDefn: "/application/aboutMe/preferred-lang",
          screenId: screenId,
          languagetoSpeak: getValues("languagetoSpeak"),
          languagetoRead: getValues("languagetoRead"),
          applicationLanguage: getValues("applicationLanguage"),
          individualId: "",
        },
      ],
    };
    try {
      showLoader();
      const res = await saveJson(payload);
      if (res?.status === 200) {
        const values = getValues();
        const isFilled = await yup
          .object({
            languagetoSpeak: yup.string().required(),
            languagetoRead: yup.string().required(),
            applicationLanguage: yup.string().required(),
          })
          .isValid(values);

        handleNext(isFilled);
        navigations.toYourInfo();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const onBack = () => {
    navigations.toDashboard();
  };

  const datas = pageData?.preflangInfo?.[0];

  return (
    <ScreenProvider labels={labels}>
      <PageContainer
        title="Preferred Language"
        onNext={handleSubmit(onSave)}
        onBack={onBack}
      >
        <Grid container xs={12} lg={6} spacing={2}>
          <Grid item xs={12} lg={12}>
            <Field
              name="languageApplication"
              label="What language do you want to complete this application in?"
            >
              <Dropdown
                sx={{ mt: "1rem" }}
                control={control}
                name="applicationLanguage"
                options={languageOpts}
              />
            </Field>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Field
              name="languageRead"
              label="What language do you prefer to read?"
            >
              <Dropdown
                sx={{ mt: "1rem" }}
                control={control}
                name="languagetoRead"
                options={languageOpts}
              />
            </Field>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Field
              name="languageSpeak"
              label="What language do you prefer to speak?"
            >
              <Dropdown
                sx={{ mt: "1rem" }}
                control={control}
                name="languagetoSpeak"
                options={languageOpts}
              />
            </Field>
          </Grid>
          <Grid item xs={12} lg={12} mt={3}>
            <Box
              sx={{
                background: "#D3E8F8",
                padding: "2rem",
              }}
            >
              <Typography variant="h4">
                {labels?.languageHelp ?? "Need Language Help?"}
              </Typography>
              <Typography variant="h4" sx={{ mt: ".5rem" }}>
                <span style={{ color: "var(--primary-dark)" }}>
                  {labels?.contactOffice ?? "Contact your local office."}
                </span>
                {labels?.needInterpreter ?? "If you need a free Interpreter"},
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </PageContainer>
    </ScreenProvider>
  );
}

PreferredLang.propTypes = {};

export default PreferredLang;
