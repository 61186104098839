import axios from "axios";

const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_BASE_URL,
  // headers: {
  //   Role: "cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN",
  //   uid: "localUser",
  //   Uniqueid: "mydhr30bf2179ad6f4d50837443ab3",
  //   "Content-Type": "application/json",
  // },
});

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_KEY_CLOCK,
  withCredentials: true,
});

export const authApiCreate = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_KEY_CLOCK_REGISTER,
});

export const smartyStreets = axios.create({
  baseURL: process.env.REACT_APP_DMS_URL,
});

export default HttpClient;
