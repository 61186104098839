import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TabularView from "../../../components/TabularView";
import SummaryContent from "../SummaryContent";
import { webReferenceIds } from "../../../store/selectors/appSelector";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

function CaseInfo({
  hohData,
  getLkpMatch,
  memberInfo,
  labels,
  raceDis,
  summaryData,
}) {
  // console.log(hohData, "hoh");
  // console.log(memberInfo, 5858);
  const webReferenceId = useSelector(webReferenceIds);
  const race =
    summaryData?.inYourInformation?.[0]?.clientContainer?.client?.race
      ?.primaryRace ?? {};
  const dob =
    summaryData?.inYourInformation?.[0]?.clientContainer?.client?.dob ?? {};
  const { consumerInfo } = summaryData ?? {};
  console.log(
    summaryData?.inYourInformation?.[0]?.clientContainer?.client?.race
      ?.primaryRace,
    55555
  );

  // const { ethnicity, race } = raceDis ?? {};
  const columns = useMemo(() => {
    return [
      {
        headerName: "Web ID",
        value: webReferenceId ?? "-",
      },
      {
        headerName: labels?.DOB ?? "DOB",

        value: dayjs(dob).format("MM-DD-YYYY"),
      },

      {
        headerName: labels?.raceAndOrigin ?? "Race",
        value: getLkpMatch("Race", race) ?? "-",
      },
    ];
  }, [getLkpMatch, hohData]);
  // const columns = useMemo(() => {
  //   return [
  //     {
  //       headerName: "Web ID",
  //       value: webReferenceId ?? "-",
  //     },
  //     {
  //       headerName: "DOB",

  //       value: "",
  //     },
  //     {
  //       headerName: "Gender",

  //       value: "M",
  //     },
  //   ];
  // }, []);

  const hohName = useMemo(() => {
    const firstName = consumerInfo?.firstName ?? {};
    const lastName = consumerInfo?.lastName ?? {};
    return `${firstName} ${lastName}`;
  }, [memberInfo]);
  // const columns = [];

  return (
    <SummaryContent title={"Case Information"}>
      <Stack direction="row" spacing={4}>
        <Stack alignItems="center" spacing={1}>
          <Avatar sx={{ width: 100, height: 100 }} />
          <Typography variant="h6">{hohName}</Typography>
          <Typography color="var(--primary-light)" variant="h5">
            HOH
          </Typography>
        </Stack>
        <Box>
          <TabularView
            sx={{
              table: {
                width: "auto",
              },
              td: {
                border: 0,
                padding: "0 1.25rem 0.5rem 0",
              },
            }}
            headerAxis="y"
            columns={columns}
            headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
            cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
          />
        </Box>
      </Stack>
    </SummaryContent>
  );
}

CaseInfo.propTypes = {
  headOfHouse: PropTypes.shape({}),
};

CaseInfo.defaultProps = {
  headOfHouse: {},
};

export default CaseInfo;
