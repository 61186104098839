import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import useLookup from "../../hooks/useLookup";
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import dayjs from "dayjs";
import { PlayArrow, FileDownloadOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import HttpClient from "../../utils/HttpClient";
import { SEARCH_CASE } from "../../services/apiUrls";
import useMount from "../../hooks/useMount";
import usePageApi from "../../hooks/usePageApi";
import { filter, includes, map, size, some } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveControlId,
  setWebReferenceId,
} from "../../store/slices/appSlice";
import { useNavigate } from "react-router-dom";
import CardDatas from "./Card";
import usePageNav from "../../hooks/usePageNav";
import {
  getCaseInitiator,
  getPdfGen,
  realTimeCaseStatus,
} from "../../services/apiRequests";
import CoTextField from "../../controllers/CoTextField";
import { maxLength, number } from "../../utils/normalizers";
import { useForm } from "react-hook-form";
import useLoader from "../../hooks/useLoader";
import { applyBenefits } from "../../services/apiPayload";
import useTabs from "../../hooks/useTabs";
import { getAuthToken } from "../../store/selectors/authSelector";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TabularView from "../../components/TabularView";
import { showErrorToast } from "../../utils/services/toast";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  SnapIconProgram,
  MedicareIcone,
  MedicareFillIcon,
  SnapFillIcom,
  CashAssistanceFill,
  CashAssistanceIcon,
  WaiverIcon,
  WaiverFill,
  LongTermIcon,
  LongTermFill,
  EmergencyFill,
  EmergencyIcon,
  QRScan,
} from "../../assets/icons";

const BenifitsSummary = (datas) => {
  return (
    <Box style={{ padding: "2rem" }}>
      <Typography variant="h5" sx={{ color: "var(--grey-400)" }}>
        Benifits Summary
      </Typography>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function Dashboard(props) {
  const [benefitesApplied, setBenefitesApplied] = useState([]);
  const token = useSelector(getAuthToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const { labels, saveJson } = usePageApi({ screenId: 428 });
  const [columnsData, setColumnsData] = useState();
  const [rowData, setRowData] = useState();
  const [count, setCount] = useState();
  const [cID, setcID] = useState("");
  const [getId, seGetID] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const { saveJson: saveCase, retrieveJson } = usePageApi({ screenId: 146 });
  const { getLkpMatch, getOptions } = useLookup("Dashboard");
  const [totalpages, setTotalPages] = useState(10);
  const {
    Tab: DashbordTab,
    Tabs: DashbordTabs,
    TabPanel: DashbordTabPanel,
    activeTab: dashbordActiveTab,
    onTabChange: dbonTabChange,
    TabContent: DashbordTabContent,
  } = useTabs();
  const [benefitPrograms, setBenefitProgram] = useState({
    WA: false,
    CH: false,
    EA: false,
    LT: false,
    MA: false,
    FS: false,
  });

  const caseTableHeader = [
    "Name",
    "Gender",
    "DOB",
    "Social Security",
    "Status",
    "Relationship to HOH",
  ];

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    fetchApps({ page: page, pageSize: rowsPerPage });
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };

  const onTabChange = (e, value) => {
    setActiveTab(value);
  };

  const colorCodeMap = {
    CL: "var(--closed-status)",
    IP: "var(--inProgress-status)",
    OP: "var(--completed-status)",
    PE: "var(--pending-status)",
    RE: "var(--submitted-status)",
  };
  const [apps, setApps] = useState({});

  const fetchApps = async (data) => {
    let webRefId = null;
    let payload = {
      search: {
        firstName: null,
        lastName: null,
        caseId: getValues("caseId") || null,
        controlId: null,
        webRefId: webRefId,
        vendorId: null,
        cboId: null,
        userRole: "CP_NH_STAFF",
        caseStatus: null,
        uniqueId: token?.sub,
        appTypeCd: "AP",
        searchLinkedCases: "N",
      },
      results: [],
      paginationData: {
        resultsPerPage: data.pageSize,
        pageNumber: data.page + 1,
      },
      range: null,
      systemCd: "",
    };
    try {
      showLoader();
      const res = await HttpClient.post(SEARCH_CASE, payload);
      if (res.status === 200) {
        setApps(res.data);
      }
    } catch (error) {
      //
    } finally {
      hideLoader();
    }
  };

  const { watch, control, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      caseId: "",
    },
  });

  function Row(props) {
    const { row, columns } = props;
    const [open, setOpen] = useState(false);
    const [realTimeStatus, setRealTimeStatus] = useState();
    const collapse = async (row) => {
      const caseId = row?.caseId;
      if (!open) {
        try {
          const res = await realTimeCaseStatus(caseId);
          setRealTimeStatus(res.data[0]);
        } catch (error) {
          showErrorToast(error);
        }
      }
      setOpen(!open);
    };

    const caseSummaryData1 = useMemo(() => {
      return [
        {
          headerName: "Name",
          value: `${realTimeStatus?.firstName} ${realTimeStatus?.lastName}`,
        },
        {
          headerName: "Cade Id",
          value: realTimeStatus?.caseId,
        },
        {
          headerName: "Program",
          value: realTimeStatus?.programType,
        },
        {
          headerName: "Start Date",
          value: realTimeStatus?.effectiveBeginDt,
        },

        {
          headerName: "Email",
          value: "email",
        },
        {
          headerName: "Case Worker",
          value: "cae Worker",
        },
      ];
    }, [realTimeStatus]);

    const statusDesign = (activity) => {
      return (
        <Box
          style={{
            borderRadius: "7%",
            padding: "0.3rem 0.2rem",
            backgroundColor: `${colorCodeMap[activity]}`,
            display: "flex",
            gap: "0.8rem",
          }}
        >
          <Icon>
            {activity === "IP" ? <TaskAltIcon /> : <CancelOutlinedIcon />}
          </Icon>

          <Typography>{activity}</Typography>
        </Box>
      );
    };

    const hohDetails = useMemo(() => {
      const dataSets = realTimeStatus?.hohDetails?.map((obj, id) => {
        return (
          <TableRow>
            <TableCell align="center">{obj?.name}</TableCell>
            <TableCell align="center">
              {getLkpMatch("Gender", obj.gender) || ""}
            </TableCell>
            <TableCell align="center">
              {dayjs(obj.dob).format("MMM DD, YYYY")}
            </TableCell>
            <TableCell align="center"> {obj.socialSecurity || ""}</TableCell>
            <TableCell align="center"> {statusDesign("IP")}</TableCell>
            <TableCell align="center">
              {getLkpMatch("IndividualRelationship", obj.relationshipToHoh) ||
                ""}
            </TableCell>
          </TableRow>
        );
      });
      return dataSets || [];
    }, [realTimeStatus]);

    const caseSummaryData2 = useMemo(() => {
      return [
        {
          headerName: "Lase Name",
          value: realTimeStatus?.lastName,
        },
        {
          headerName: "Web Id",
          value: "web id",
        },
        {
          headerName: "Created By",
          value: "created by",
        },
        {
          headerName: "End Date",
          value: realTimeStatus?.effectiveEndDt,
        },

        {
          headerName: "Phone",
          value: "phone",
        },
        {
          headerName: "County Office",
          value: "County office",
        },
      ];
    }, [realTimeStatus]);

    const renderCell = (value) => {
      // console.log(value);
      switch (value) {
        case "createdDt":
          return dayjs(row[value]).format("MMM DD, YYYY");
          break;
        case "newAction":
          if (row.caseStatus === "In Progress") {
            return (
              <div className={{}}>
                <Tooltip title="Resume" arrow>
                  <IconButton onClick={(e) => handleResumeClick(row)}>
                    <PlayArrow sx={{ color: "var(--primary-light)" }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          } else if (row.caseId !== null) {
            return (
              <div>
                <Tooltip title="Download" arrow>
                  <IconButton onClick={(e) => handlePDFDownload(row)}>
                    <FileDownloadOutlined />
                  </IconButton>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      collapse(row);
                    }}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Tooltip>
              </div>
            );
          } else if (row.caseStatus === "Submitted") {
            return (
              <div className={{}}>
                <Tooltip title="Download" arrow>
                  <IconButton onClick={(e) => handlePDFDownload(row)}>
                    <FileDownloadOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            );
          } else {
            return null;
          }
          break;
        default:
          return row[value];
          break;
      }
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          {map(columns, ({ field }) => {
            return (
              <TableCell component="th" scope="row">
                {renderCell(field)}
              </TableCell>
            );
          })}
        </TableRow>
        {open && (
          <TableRow>
            <TableCell
              style={{
                paddingBottom: "0.5rem 0.5rem",
                paddingTop: 0,
                backgroundColor: "var(--grey-200)",
              }}
              colSpan={12}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Tabs onChange={onTabChange} value={activeTab}>
                  <Tab activeTab={0} label="My Info" />
                  <Tab activeTab={1} label="Activity" />
                  <Tab activeTab={2} label="Document" />
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                  <Box
                    sx={{
                      margin: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paper
                      style={{
                        width: "70%",
                        padding: "2rem",
                        boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Box>
                        <Box
                          style={{
                            marginBottom: "2rem",
                          }}
                        >
                          <Stack
                            sx={{
                              color: "var(--grey-400)",
                              paddingBottom: "0.7rem",
                              marginBottom: "1rem",
                              borderBottom: "1px solid grey",
                            }}
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="h5"
                              sx={{ color: "var(--grey-400)" }}
                            >
                              Case Summary
                            </Typography>
                            <Box
                              style={{
                                display: "flex",
                                gap: "0.6rem",
                                marginRight: "5rem",
                              }}
                            >
                              <Box
                                style={{
                                  backgroundColor: `${
                                    colorCodeMap[realTimeStatus?.caseStatus]
                                  }`,
                                  width: "1rem",
                                  height: "1rem",
                                  borderRadius: "50%",
                                }}
                              ></Box>
                              {getLkpMatch(
                                "CaseStatusCode",
                                realTimeStatus?.caseStatus
                              )}
                            </Box>
                          </Stack>

                          <Box sx={{ display: "flex" }}>
                            <TabularView
                              sx={{
                                table: {
                                  width: "auto",
                                },
                                td: {
                                  border: 0,
                                  padding: "0 1.25rem 0.5rem 0",
                                },
                              }}
                              headerAxis="y"
                              columns={caseSummaryData1}
                              headerComponent={{
                                variant: "h6",
                                color: "var(--grey-400)",
                              }}
                              cellComponent={{
                                variant: "body2",
                                color: "var(--grey-500)",
                              }}
                            />
                            <TabularView
                              sx={{
                                table: {
                                  width: "auto",
                                },
                                td: {
                                  border: 0,
                                  padding: "0 1.25rem 0.5rem 0",
                                },
                              }}
                              headerAxis="y"
                              columns={caseSummaryData2}
                              headerComponent={{
                                variant: "h6",
                                color: "var(--grey-400)",
                              }}
                              cellComponent={{
                                variant: "body2",
                                color: "var(--grey-500)",
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          style={{
                            borderBottom: " 1px solid #6C6C6C",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              color: "var(--grey-400)",
                              paddingBottom: "1rem",
                            }}
                          >
                            HOH Details
                          </Typography>
                        </Box>

                        <Table
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none",
                            },
                            "& .MuiTableCell-sizeMedium": {
                              paddingLeft: "5px ",
                            },
                          }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              {caseTableHeader.map((obj) => {
                                return (
                                  <TableCell
                                    align="right"
                                    component="th"
                                    scope="row"
                                    sx={{ textAlign: "start" }}
                                  >
                                    <Typography
                                      variant="h5"
                                      sx={{ color: "var(--grey-400)" }}
                                    >
                                      {obj}
                                    </Typography>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>{hohDetails}</TableBody>
                        </Table>
                      </Box>
                    </Paper>
                    <Paper
                      style={{
                        width: "27%",
                        boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <BenifitsSummary />
                    </Paper>
                  </Box>
                </TabPanel>
                <TabPanel value={activeTab} index={1}></TabPanel>
                <TabPanel value={activeTab} index={2}></TabPanel>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  Row.propTypes = {};

  useMount(() => {
    fetchApps({ page: 0, pageSize: 5 });
  });

  const handleResumeClick = (params) => {
    setcID(params?.controlId);
    dispatch(setActiveControlId(params?.controlId));
    dispatch(setWebReferenceId(params?.webRefId));
    navigate("/application/aboutMe/preferred-lang");
  };
  const postApplyBenefits = async () => {
    const programs = [];
    for (let obj in benefitPrograms) {
      if (benefitPrograms[obj]) {
        programs.push(obj);
      }
    }
    try {
      if (programs.length) {
        showLoader();
        const getIDdeatils = await getCaseInitiator();
        if (getIDdeatils.status === 200) {
          dispatch(setActiveControlId(getIDdeatils.data.controlId));
          dispatch(setWebReferenceId(getIDdeatils.data.webRefId));
          saveCase(applyBenefits(getIDdeatils.data.controlId, programs), {
            controlId: getIDdeatils.data.controlId,
          });

          navigations.toPreferredLang();
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  const handlePDFDownload = async (params) => {
    const id = params?.controlId;
    try {
      showLoader();
      const res = await getPdfGen(id);
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
  };

  useMemo(() => {
    const columns = [
      {
        field: "webRefId",
        headerName: `${labels?.appId ?? "Application ID"}`,
        headerClassName: "grid_header",
        flex: 1,
      },
      {
        field: "caseId",
        headerName: `${labels?.caseId ?? "Case ID"}`,
        headerClassName: "grid_header",
        flex: 1,
        renderCell: (params) => {
          return params.value ?? "-";
        },
      },
      {
        field: "firstName",
        headerName: `${labels?.fName ?? "First Name"}`,
        headerClassName: "grid_header",
        flex: 1,
      },
      {
        field: "lastName",
        headerName: `${labels?.lName ?? "Last Name"}`,
        headerClassName: "grid_header",
        flex: 1,
      },
      {
        field: "caseStatus",
        headerName: `${labels?.status ?? "Status"}`,
        headerClassName: "grid_header",
        flex: 1,
      },
      {
        field: "createdDt",
        headerName: `${labels?.creDate ?? "Created Date"}`,
        headerClassName: "grid_header",
        flex: 1,
        renderCell: (params) => dayjs(params?.value).format("MMM DD, YYYY"),
      },
      {
        field: "createdByFullName",
        headerName: `${labels?.creBy ?? "Created By"}`,
        headerClassName: "grid_header",
        flex: 1,
      },
      {
        field: "newAction",
        headerName: `${labels?.actions ?? "Actions"}`,
        headerClassName: "grid_header",
        flex: 1,
        renderCell: (params) => {
          if (params.row?.caseStatus === "In Progress") {
            return (
              <div className={{}}>
                <Tooltip title="Resume" arrow>
                  <IconButton onClick={(e) => handleResumeClick(params)}>
                    <PlayArrow sx={{ color: "var(--primary-light)" }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          } else if (params.row?.caseStatus === "Submitted") {
            return (
              <div className={{}}>
                <Tooltip title="Download" arrow>
                  <IconButton onClick={(e) => handlePDFDownload(params)}>
                    <FileDownloadOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ];
    switch (dashbordActiveTab) {
      case 0:
        setColumnsData(columns);
        setRowData(apps.results);
        setCount(size(apps.results));
        break;
      case 1:
        setColumnsData(columns);
        setRowData(
          filter(apps.results, (value) => {
            if (value.caseStatus === "Approved") {
              setCount(size(value));
              return value;
            }
          })
        );
        break;
      default:
    }
  }, [dashbordActiveTab, apps]);

  const handleBenefitsChoose = (e) => {
    setBenefitProgram({
      ...benefitPrograms,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <div>
      <Divider />
      <div className={styles.banner}>
        <Typography sx={{ fontSize: "4rem", color: "white" }} variant="body1">
          {labels?.welcomeBack ?? " Welcome back,"}{" "}
          <strong>{token?.name}</strong>
        </Typography>
        <Typography sx={{ color: "white" }} variant="body1">
          {labels?.kyybaCarePortals ??
            "Apply Benefits and Manage your benefits with our KConnect portals"}
        </Typography>
      </div>
      <div className={styles.content}>
        <Stack
          direction="column"
          gap={2}
          sx={{ mt: "-7rem", mb: "3rem", background: "white", padding: "2rem" }}
        >
          <Typography variant="h4">
            What program(s) is your household applying for today?
          </Typography>
          <Box
            sx={{
              border: "1px solid var(--grey-250)",
              padding: "1rem",
              borderRadius: "0.4rem",
            }}
          >
            <Grid container xs={12} spacing={2} flexWrap="nowrap">
              <Grid item xs={9} sx={{ display: "grid" }}>
                <Grid
                  container
                  sx={{
                    border: "1px solid var(--grey-250)",
                    padding: "1rem",
                    borderRadius: "0.4rem",
                  }}
                >
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label className={styles.yes}>
                      <input
                        type="checkbox"
                        onClick={handleBenefitsChoose}
                        style={{ display: "none" }}
                        name="FS"
                        value="Y"
                      />
                      <Box
                        className={
                          [benefitPrograms.LT, benefitPrograms.WA].some(
                            (obj) => obj
                          )
                            ? styles.disableProgram
                            : styles.fillIcon
                        }
                      >
                        {benefitPrograms.FS ? (
                          <SnapFillIcom />
                        ) : (
                          <SnapIconProgram />
                        )}
                      </Box>
                    </label>
                    <Typography
                      variant="subtitle1"
                      sx={{ textAlign: "center" }}
                    >
                      WIC
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        sx={{ padding: "0.5rem 2.7rem", fontSize: "1.2rem" }}
                        onClick={
                          postApplyBenefits
                          // navigations.toSnapQualifier()
                        }
                      >
                        {labels?.applyBenefits ?? "Apply Benefit"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Box
                  sx={{
                    border: "1px solid var(--grey-250)",
                    padding: "1rem",
                    borderRadius: "0.4rem",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Box className={styles.fillIcon}>
                      <QRScan />
                    </Box>
                    <Typography
                      variant="subtitle1"
                      sx={{ textAlign: "center", marginTop: "0.2rem" }}
                    >
                      Existing Case
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CoTextField
                        normalizers={[number, maxLength(9)]}
                        sx={{ "& fieldset": { border: "none" } }}
                        control={control}
                        name="caseId"
                        placeholder="Search by Case Id"
                      />
                      <IconButton
                        type="button"
                        onClick={handleChangePage}
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <SearchIcon sx={{ color: "var(--primary-dark)" }} />
                      </IconButton>
                    </Paper>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <DashbordTabs
          sx={{ backgroundColor: "#ffff" }}
          onChange={dbonTabChange}
          value={dashbordActiveTab}
        >
          <DashbordTab label={`All Application ${count}`} />
          <DashbordTab label={`Report to Change ${count}`} />
          <DashbordTab label="Redetermination" />
          <DashbordTab label="Schedule Appointment" />
        </DashbordTabs>
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {map(columnsData, (data) => {
                    return (
                      <TableCell align="left">{data.headerName}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {map(rowData, (row, index) => {
                  return <Row key={index} row={row} columns={columnsData} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={apps?.paginationData?.totalRecords || 0}
            rowsPerPage={rowsPerPage || 10}
            page={page || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </div>
      <Grid
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "var(--grey-200)",
          paddingTop: "1rem",
          paddingLeft: " 2rem",
          paddingRight: "2rem",
        }}
      >
        <CardDatas labels={labels} />
      </Grid>
    </div>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
