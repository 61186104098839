import { useNavigate, useParams, useMatch } from "react-router";

function usePageNav() {
  const navigate = useNavigate();
  const params = useParams();
  const isCaseHome = useMatch("/dashboard/caseInquiry/caseHome/:caseId");
  const _caseId = params.caseId ?? "";
  const _controlId = params.controlId ?? "";
  const onBack = () => navigate(-1);

  const navigations = {
    toLogin() {
      navigate(`/login`);
    },
    toDashboard() {
      navigate(`/dashboard`);
    },
    toPreferredLang() {
      navigate("/application/aboutMe/preferred-lang");
    },
    toYourInfo() {
      navigate("/application/aboutMe/your-information");
    },
    toAddressInformation() {
      navigate("/application/aboutMe/currentAddress");
    },
    toContactInfo() {
      navigate("/application/aboutMe/contact-info");
    },
    toRequestedProgram() {
      navigate("/application/memberInfo/requested-program");
    },
    toAddHoh() {
      navigate("/application/member/addHousehold-member");
    },
    toFileupload() {
      navigate("/application/upload/document-upload");
    },
    toDocumentUpload() {
      navigate(`/application/upload/document-upload`);
    },
    toRegistration() {
      navigate("/registration");
    },
    toLandingPage() {
      navigate("/landingPage");
    },
    toHouseHoldMember() {
      navigate("/application/memberInfo/household-Member");
    },
    toFamilySurvey() {
      navigate("/application/household-details/family-survey");
    },
    toEmployement() {
      navigate("/application/incomeInfo/employment");
    },
    toSelfEmployement() {
      navigate("/application/incomeInfo/selfemployment");
    },
    toAdditionalIncom() {
      navigate("/application/incomeInfo/additionalincome");
    },
    toIncomInfo() {
      navigate("/application/incomeInfo/income");
    },
    toSignature() {
      navigate("/application/submission/signature");
    },
    toWicProgram() {
      navigate("/application/programinfo/wicprogram");
    },
    toCitizenShip() {
      navigate("/application/aboutMe/citizenship-details");
    },
    toFileupload() {
      navigate("/application/fileupload/document-upload");
    },
    toCaseSummary(){
      navigate("/application/submission/summary")
    }
  };

  return {
    params,
    onBack,
    navigations,
    caseId: _caseId,
    isCaseHome,
  };
}

export default usePageNav;
