import React, { useEffect, useMemo, useState } from "react";
import ScreenProvider from "../../../containers/ScreenProvider";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import usePageApi from "../../../hooks/usePageApi";
import { useFieldArray, useForm } from "react-hook-form";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import CoTextField from "../../../controllers/CoTextField";
import { formatCurrency } from "../../../utils/normalizers";
import PageContainer from "../../../components/PageContainer";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { isEmpty, map } from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import usePageNav from "../../../hooks/usePageNav";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useLoader from "../../../hooks/useLoader";
import { showErrorToast } from "../../../utils/services/toast";
import useLookup from "../../../hooks/useLookup";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadFile from "../../../components/FileUpload";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useDynamicNav from "../../../hooks/useDynamicNav";

const SelfEmployement = ({ screenId }) => {
  const { pageData, labels, saveJson } = usePageApi({
    screenId,
    callRetrieveStatus: true,
  });
  const [openDocModal, setOpenDocModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [idData, setIdData] = useState([]);
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const { getLkpMatch, getOptions } = useLookup("SelfEmployement");
  const { pageData: memberPageDate } = usePageApi({
    screenId: 206,
    callRetrieveStatus: true,
  });

  const userSchema = yup.object().shape({
    employerDetails: yup.array().of(
      yup.object().shape({
        householdMember: yup
          .string()
          .required("Please choose a household member"),
        incomeAmount: yup.string().required("Please enter the amount"),
        work: yup.string().required("Please choose a Type of work"),
        monthlyAmount: yup.string().required("Please enter the amount"),
      })
    ),
  });

  const {
    watch,
    control,
    getValues,
    setValue,
    reset,
    //errors,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    // resolver: yupResolver(userSchema),

    defaultValues: {
      employerDetails: [],
    },
  });

  const { workType, incomeOptions } = useMemo(() => {
    return {
      workType: getOptions("Type Of Work"),
      incomeOptions: getOptions("Income"),
    };
  }, [getOptions]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "employerDetails",
  });

  const handleAddEmployement = () => {
    append({
      householdMember: "",
      incomeAmount: "",
      work: "",
      monthlyAmount: "",
    });
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({
        householdMember: "",
        incomeAmount: "",
        work: "",
        monthlyAmount: "",
      });
    }
  }, []);

  const onSave = async (value) => {
    try {
      showLoader();
      const res = await saveJson(value);
      if (res.status === 200) {
        handleNext();
        navigations.toAdditionalIncom();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (isEmpty(pageData)) {
    } else {
      reset(pageData);
    }
  }, [pageData]);

  const onBack = () => {
    navigations.toEmployement();
  };

  const handeChange = async (event) => {
    let { value } = event.target;
    setIdData(value);
  };

  const memberList = () => {
    const data = map(memberPageDate, (value) => {
      return {
        label: `${value?.clientContainer?.client?.personName?.firstName} ${value?.clientContainer?.client?.personName?.lastName}`,
        value: value?.individualId,
      };
    });
    return data;
  };

  useEffect(() => {
    const data = memberList();
    setMembers(data);
  }, [memberPageDate]);

  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="Self - Employment"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Typography variant="h5">
            List anyone in your household who is self-employed.
          </Typography>
          {map(fields, (amount, index) => (
            <Box
              key={amount?.id}
              // sx={{ padding: "1rem", border: "solid 1px var(--grey-200)" }}
            >
              <Grid container>
                <Grid sm={12} lg={5.15}>
                  <Typography variant="h4" my={2}>
                    Employer Details {index + 1}
                  </Typography>
                </Grid>
                <Grid
                  sm={12}
                  lg={5.15}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<UploadFileIcon />}
                    onClick={() => setOpenDocModal(true)}
                  >
                    {/* {labels?.upload ?? " Upload files"} */}Upload files
                  </Button>
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={2}>
                <Grid item sm={12} lg={5.15}>
                  <Stack>
                    <Field
                      label="Household Member"
                      name={`employerDetails.${index}.householdMember`}
                      error={
                        errors?.employerDetails?.[index]?.householdMember
                          ?.message
                      }
                      showError={
                        errors?.employerDetails?.[index]?.householdMember
                          ?.message
                      }
                      required
                    >
                      <Dropdown
                        control={control}
                        placeholder="Select Member"
                        name={`employerDetails.${index}.householdMember`}
                        options={members}
                        onChangeCustom={handeChange}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item sm={12} lg={5.15}>
                  <Field
                    label="Type of Work"
                    name={`employerDetails.${index}.work`}
                    error={errors?.employerDetails?.[index]?.work?.message}
                    showError={errors?.employerDetails?.[index]?.work?.message}
                    required
                  >
                    <Dropdown
                      control={control}
                      placeholder="Select Work"
                      name={`employerDetails.${index}.work`}
                      options={workType}
                    />
                  </Field>
                </Grid>
                <Grid item sm={12} lg={5.15}>
                  <Field
                    label="What is monthly income (before expenses)?"
                    name={`employerDetails.${index}.incomeAmount`}
                    error={
                      errors?.employerDetails?.[index]?.incomeAmount?.message
                    }
                    showError={
                      errors?.employerDetails?.[index]?.incomeAmount?.message
                    }
                    required
                  >
                    <CoTextField
                      name={`employerDetails.${index}.incomeAmount`}
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>

                <Grid item sm={12} lg={5.15}>
                  <Field
                    label="What are’s monthly expenses?"
                    name={`employerDetails.${index}.monthlyAmount`}
                    error={
                      errors?.employerDetails?.[index]?.monthlyAmount?.message
                    }
                    showError={
                      errors?.employerDetails?.[index]?.monthlyAmount?.message
                    }
                    required
                  >
                    <CoTextField
                      control={control}
                      name={`employerDetails.${index}.monthlyAmount`}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid sm={6} lg={5.15}>
                  <Button
                    variant="outlined"
                    onClick={handleAddEmployement}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Add Self Employment
                  </Button>
                </Grid>
                {index === 0 ? (
                  ""
                ) : (
                  <Grid
                    sm={6}
                    lg={5.15}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      sx={{
                        color: "var(--grey-400)",
                        borderColor: "var(--grey-400)",
                      }}
                      variant="outlined"
                      startIcon={<RemoveCircleOutlineIcon />}
                      onClick={() => remove(index)}
                    >
                      Remove
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          ))}
        </PageContainer>
      </Box>
      <UploadFile
        docOpen={openDocModal}
        setDocOpen={setOpenDocModal}
        defaultDocType={"IN"}
        subTypeOptions={incomeOptions}
        activeIndex={idData}
      />
    </ScreenProvider>
  );
};

export default SelfEmployement;
