import { string, number, date, boolean } from "yup";
import errorDatas from "./ErrorMsg.json";
import * as yup from "yup";

import dayjs from "dayjs";
const {
  requiredField,
  onlyString,
  maxCharacter,
  onlyNumbers,
  positive,
  D4code,
  D5code,
  D3code,
  selectDate,
  onlyCharsNumbers,
} = errorDatas;
//export const lengthRequired = mixed().required().max(50);
export const mandatory = (fieldName) =>
  string().required(fieldName ?? "Please Choose any Option");

export const onlyNumber = (names) =>
  number().typeError(`Enter ${names}`).positive();
export const onlyAlphabtes = (fieldName) =>
  string()
    .typeError(`${fieldName} is required`)
    .required(`${fieldName} is required`)
    .min(2)
    .max(50);

export const zipCodes = (num, length) => {
  switch (length) {
    case 5: {
      return string().matches(
        /^\d{5}$/,
        "Field must contain exactly 5 numbers"
      );
    }
    case 4: {
      return string().matches(
        /^\d{4}$/,
        "Field must contain exactly 4 numbers"
      );
    }
  }
};
export const dateField = date()
  .typeError(selectDate)
  .min(dayjs().format("YYYY/MM/DD"))
  .max(dayjs("2025/08/19"));
export const validCharacters = (fieldName) =>
  string().required(`Please Enter${fieldName}`);
export const email = string().email().required();
export const website = string().url().nullable();
export const radioFields = boolean().required();
