import React from "react";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { map } from "lodash";

export default function CRadioButton({ control, name, radioArray, row }) {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <RadioGroup row={row} {...field}>
              {map(radioArray, (data) => {
                return (
                  <FormControlLabel
                    value={data.value}
                    control={<Radio />}
                    label={data.label}
                    disabled={data?.disabled}
                  />
                );
              })}
            </RadioGroup>
          );
        }}
      />
    </div>
  );
}
