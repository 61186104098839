import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectNavLinks } from "../../../store/selectors/navigationSelector";
import NavLinkButton from "../../../components/NavButton/NavLinkButton";
import NavModuleButton from "../../../components/NavButton/NavModuleButton";
import { find, includes, map, some } from "lodash";
import { Collapse, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { selectScreenStatuses } from "../../../store/selectors/appSelector";

function SideNav(props) {
  const location = useLocation();
  const screenStatuses = useSelector(selectScreenStatuses);
  const navLinks = useSelector(selectNavLinks);
  const [activeModule, setActiveModule] = useState(-1);
  const [highlightedModule, setHighlightedModule] = useState(-1);

  const toggleModule = (id) => {
    const isOpen = activeModule === id;
    if (isOpen) {
      setActiveModule(-1);
    } else {
      setActiveModule(id);
    }
  };

  useEffect(() => {
    const activeId = find(navLinks, ({ subNavs }) =>
      some(subNavs, { screenDefn: location.pathname })
    )?.moduleId;
    setActiveModule(activeId);
    setHighlightedModule(activeId);
  }, [location.pathname, navLinks]);

  return (
    <div style={{ flexGrow: 1 }}>
      {/* <Stack p="1rem">
        <Typography sx={{ fontSize: "1.25rem" }} variant="h4grey">
          Right Now
        </Typography>
        <Typography
          sx={{ fontSize: "1.75rem", color: "var(--primary-text-color)" }}
          variant="h4grey"
        >
          Step 2 of 8
        </Typography>
      </Stack> */}
      {map(navLinks, (navModule) => {
        const { tagTxt, moduleId, subNavs } = navModule;
        const isOpen = moduleId === activeModule;
        const currentModuleStatus = find(screenStatuses, {
          moduleId: moduleId,
        });
        return (
          <>
            <NavModuleButton
              title={tagTxt}
              moduleid={moduleId}
              open={isOpen}
              active={moduleId === highlightedModule}
              onClick={() => toggleModule(moduleId)}
              filled={currentModuleStatus?.status === "completed"}
              pending={currentModuleStatus?.status === "pending"}
            />
            <Collapse in={isOpen}>
              {map(subNavs, (link) => {
                const isFilled = includes(
                  currentModuleStatus?.completedScreens,
                  link.screenId
                );
                const pending = includes(
                  currentModuleStatus?.pending,
                  link.screenId
                );
                return (
                  <NavLinkButton
                    title={link.tagTxt}
                    to={link?.screenDefn}
                    filled={isFilled}
                    pending={pending}
                  />
                );
              })}
            </Collapse>
          </>
        );
      })}
    </div>
  );
}

SideNav.propTypes = {};

export default SideNav;
