import * as React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import { FileUploader } from "react-drag-drop-files";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Divider, Box, Typography, TextField } from "@mui/material";
import styles from "./index.module.css";
import {
  documentUpload,
  getIndividualDocument,
} from "../../services/apiRequests";
import { useForm } from "react-hook-form";
import useLookup from "../../hooks/useLookup";
import Dropdown from "../Dropdown";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataGridTable from "./dataGridTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import ActionButtons from "../ActionButtons";
import useLoader from "../../hooks/useLoader";
import { showErrorToast, showSuccessToast } from "../../utils/services/toast";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { selecttActiveContId } from "../../store/selectors/appSelector";
import { useSelector } from "react-redux";

export default function UploadFile({
  setDocOpen,
  docOpen,
  activeIndex,
  defaultDocType,
  getName,
  subTypeOptions = [],
}) {
  const [file, setFile] = useState(null);
  const [fileApiData, setFileApiData] = useState([]);
  // const [deleteSingleData, setDeleteSingleData] = useState("");
  // const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { getOptions, getLkpMatch } = useLookup("fileUpload");
  const documentType = getOptions("Documents");
  const [subOptions, setSubOptions] = useState({});
  const [showLoader, hideLoader] = useLoader();
  const [enable, setEnable] = useState(false);
  const controlId = useSelector(selecttActiveContId);

  const { watch, control, getValues, setValue, reset } = useForm({
    // mode: "onChange",
    defaultValues: {
      documentTypeMain: "",
      documentSubType: "",
      fileName: "",
    },
  });

  const documentTypeMainWatch = watch("documentTypeMain");
  const documentSubTypeWatch = watch("documentSubType");
  const fileNameWatch = watch("fileName");

  const {
    agreementOptions,
    applicationsOptions,
    assetOptions,
    clearancesOptions,
    incomeOptions,
    disabilityOtions,
    expensesOptions,
    fiscalOptions,
    identityOptions,
    legalOptions,
    LtcOptions,
    MCPOptions,
    medicalOptions,
    qualityOptions,
    reOptions,
    referralsOptions,
    verifOptions,
    wpOptions,
  } = useMemo(() => {
    return {
      incomeOptions: getOptions("Income"),
      agreementOptions: getOptions("Agreement"),
      applicationsOptions: getOptions("Applications"),
      assetOptions: getOptions("Assets"),
      clearancesOptions: getOptions("Clearances"),
      disabilityOtions: getOptions("Disability Benefits Advocacy"),
      expensesOptions: getOptions("Expenses"),
      fiscalOptions: getOptions("Fiscal"),
      identityOptions: getOptions("Identity"),
      legalOptions: getOptions("Legal"),
      LtcOptions: getOptions("LTC Forms"),
      MCPOptions: getOptions("MCP Special Programs"),
      medicalOptions: getOptions("Medical"),
      qualityOptions: getOptions("Quality Control"),
      reOptions: getOptions("Redeterminations"),
      referralsOptions: getOptions("Referrals"),
      verifOptions: getOptions("Verifications"),
      wpOptions: getOptions("Work Program"),
    };
  }, [getOptions]);

  const handleClose = () => {
    setDocOpen(false);
    handleReset();
  };

  let handleChange = (acceptedFiles) => {
    setFile(acceptedFiles);
    setEnable(true);
  };

  let handleReset = () => {
    // to reset modal state
    setValue("documentSubType", "", { shouldDirty: false });
    setValue("fileName", "", { shouldDirty: false });
    setFileApiData([]);
    setFile(null);
  };

  useEffect(() => {
    if (subTypeOptions && subTypeOptions.length > 0) {
      setSubOptions(subTypeOptions);
    } else if (documentTypeMainWatch === "IN") {
      setSubOptions(incomeOptions);
    } else if (documentTypeMainWatch === "AG") {
      setSubOptions(agreementOptions);
    } else if (documentTypeMainWatch === "AP") {
      setSubOptions(applicationsOptions);
    } else if (documentTypeMainWatch === "AS") {
      setSubOptions(assetOptions);
    } else if (documentTypeMainWatch === "CL") {
      setSubOptions(clearancesOptions);
    } else if (documentTypeMainWatch === "DB") {
      setSubOptions(disabilityOtions);
    } else if (documentTypeMainWatch === "EX") {
      setSubOptions(expensesOptions);
    } else if (documentTypeMainWatch === "FI") {
      setSubOptions(fiscalOptions);
    } else if (documentTypeMainWatch === "ID") {
      setSubOptions(identityOptions);
    } else if (documentTypeMainWatch === "LE") {
      setSubOptions(legalOptions);
    } else if (documentTypeMainWatch === "LF") {
      setSubOptions(LtcOptions);
    } else if (documentTypeMainWatch === "MS") {
      setSubOptions(MCPOptions);
    } else if (documentTypeMainWatch === "ME") {
      setSubOptions(medicalOptions);
    } else if (documentTypeMainWatch === "QC") {
      setSubOptions(qualityOptions);
    } else if (documentTypeMainWatch === "RD") {
      setSubOptions(reOptions);
    } else if (documentTypeMainWatch === "RE") {
      setSubOptions(referralsOptions);
    } else if (documentTypeMainWatch === "VE") {
      setSubOptions(verifOptions);
    } else if (documentTypeMainWatch === "WP") {
      setSubOptions(wpOptions);
    }
  }, [documentTypeMainWatch, subTypeOptions]);

  const initialState = {
    fileFormData: file,
    individualId: activeIndex,
    verificationName: fileNameWatch,
    documentTypeCd: documentTypeMainWatch,
    lookupShortVal: documentSubTypeWatch,
    controlId: controlId,
    createDt: dayjs().format("YYYY/MM/DD"),
    createUserId: getName,
    sourceSystem: "KCARES",
    sourceCaseId: activeIndex,
  };

  const handleUploadFile = async () => {
    showLoader();
    try {
      const res = await documentUpload(initialState);
      if (res.status === 200) {
        showSuccessToast("File added successfully");
        handleClose();
        hideLoader();
      }
    } catch (error) {
      showErrorToast("Failed to Upload file");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (defaultDocType) {
      setValue("documentTypeMain", defaultDocType);
    }
  }, [defaultDocType]);

  const COLUMNS = useMemo(
    () => [
      {
        field: "docType",
        headerName: "Document Type",
        flex: 1,
        renderCell: (params) => getLkpMatch("Documents", params.row?.docType),
      },
      {
        field: "docCat",
        headerName: "Document Subtype",
        flex: 1,
        renderCell: (params) => getLkpMatch("Income", params.row?.docCat),
      },
      {
        field: "userFileName",
        headerName: "File Name",
        flex: 1,
      },
      {
        field: "action",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              {/* <ActionButtons
              // onEdit={() => handleEditModal(params?.row)}
              // onDelete={() => handleDeleteModal(params?.row)}
            /> */}
              <MoreVertIcon />
            </div>
          );
        },
      },
    ],
    [getLkpMatch]
  );
  // const handleDeleteModal = (data) => {
  //   let { id } = data;
  //   setDeleteSingleData(id);
  //   setIsDeleteOpen(true);
  // };
  // const handleEditModal = (data) => {
  //   // setEditData(data);
  //   // setEarnedOpen(true);
  // };
  // const handleDelete = async () => {
  //   try {
  //     if (fileApiData && fileApiData.length > 0) {
  //       const payload = fileApiData.filter(
  //         (item) => item.id !== deleteSingleData
  //       );
  //       setDeleteSingleData("");
  //       setIsDeleteOpen(false);
  //     }
  //   } catch (err) {
  //     console.log("ERROR", err);
  //   }
  // };

  useEffect(() => {
    api();
  }, [docOpen]);

  const api = async () => {
    try {
      showLoader();
      const res = await getIndividualDocument(activeIndex);
      if (res.status === 200) {
        setFileApiData(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Dialog
        open={docOpen}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "80vh",
          },
        }}
        aria-labelledby="document"
        aria-describedby="documentDescription"
      >
        <DialogTitle className={styles.documentTitle}>
          Add Document
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box className={styles.dialogFram}>
              <Box
                style={{
                  background: "var(--grey-200)",
                  width: "50%",
                  height: "100%",
                }}
              >
                <FileUploader
                  FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={["JPEG", "PNG", "JPG", "PDF"]}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    textAlign="center"
                  >
                    <DriveFolderUploadIcon
                      sx={{ fontSize: 200, color: "var(--grey-300)" }}
                    />
                    <Typography variant="h3" mb={3} className={styles.dropFile}>
                      Drop your files here
                    </Typography>
                    <Divider />
                    <span className={styles.orButton}>or</span>
                    <div className={styles.browseButton}>Browse</div>
                  </Box>
                </FileUploader>

                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "green",
                    paddingTop: "8px",
                  }}
                >
                  {file?.name}
                </span>
                <span
                  style={{
                    fontSize: "1.1rem",
                    display: "flex",
                    justifyContent: "center",
                    margin: "0.9rem",
                  }}
                >
                  Note:Supported file formats are .jpg .png .jpeg
                </span>
              </Box>
              <Box
                style={{
                  width: "50%",
                  padding: "3em 1em 0 1em",
                  alignSelf: "flex-start",
                }}
              >
                <Dropdown
                  control={control}
                  placeholder="Document Type"
                  name="documentTypeMain"
                  options={documentType}
                  disabled={!!defaultDocType}
                ></Dropdown>
                <Dropdown
                  control={control}
                  placeholder="Document Name"
                  name="documentSubType"
                  style={{ marginTop: "1.5rem" }}
                  options={subOptions}
                ></Dropdown>
                <TextField
                  style={{ marginTop: "1.5rem" }}
                  name="fileName"
                  placeholder="File Name"
                  control={control}
                ></TextField>
                <Stack
                  direction="row"
                  gap={2}
                  justifyContent={"end"}
                  sx={{ paddingTop: "10px" }}
                >
                  <Button
                    variant="outlined"
                    padding="10px 24px"
                    onClick={handleClose}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleUploadFile}
                    disabled={!enable}
                  >
                    Upload File
                  </Button>
                </Stack>
              </Box>
            </Box>
          </DialogContentText>

          <DataGridTable columns={COLUMNS} data={fileApiData} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ mr: "2rem", width: "6rem" }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
          setDeleteSingleData("");
        }}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteOpen(false);
              setDeleteSingleData("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
