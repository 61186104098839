import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selecttActiveContId,
  selectHohInfo,
  webReferenceIds,
} from "../../store/selectors/appSelector";
import { FETCH_HOH_INFO } from "../../store/types/appTypes";
import { setHohInfo } from "../../store/slices/appSlice";
import { getFullName } from "../../utils/helpers";

function AppDetailBar(props) {
  const dispatch = useDispatch();
  const activeControlId = useSelector(selecttActiveContId);
  const hohInfo = useSelector(selectHohInfo);
  const { firstName, lastName, age, dob } = hohInfo;
  const webReferenceId = useSelector(webReferenceIds);

  useEffect(() => {
    if (activeControlId) {
      dispatch({ type: FETCH_HOH_INFO, payload: activeControlId });
    }
    return () => {
      dispatch(setHohInfo({}));
    };
  }, [activeControlId]);

  // const retriveMember = async() => {
  //   try{
  //     const res = await HttpClient.post("/eeapi/public/consumer/jsonRetrieve", {
  //       screenDefn: "/dashboard/consumer-app/intake-app/summary/homePage",
  //       contrilId: 1296,
  //     });
  //     if(res.data){
  //       let obj =
  //     }
  //   }

  // }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container p="1rem">
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <Typography pr={1} variant="h4grey">
              Name
            </Typography>
            <Typography variant="h4">
              {getFullName(firstName, lastName)}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4grey" pr={1}>
              DOB
            </Typography>
            <Typography variant="h4">{dob}</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <Typography pr={1} variant="h4grey">
              Program
            </Typography>
            <Typography variant="h4">WIC</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <Typography pr={1} variant="h4grey">
              Application ID
            </Typography>
            <Typography variant="h4">{webReferenceId}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

AppDetailBar.propTypes = {};

export default AppDetailBar;
