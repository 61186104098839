import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Button, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";

function HohInfo({ houseMembers, getLkpMatch, labels, summaryData }) {
  const columns = useMemo(
    () => [
      {
        field: "",
        headerName: labels?.name ?? "Name",
        getValue({ firstName = {}, lastName = {} }) {
          return `${firstName} ${lastName}`;
        },
      },
      // {
      //   field: "",
      //   headerName: labels?.gender ?? "Gender",
      //   getValue({ gender }) {
      //     return getLkpMatch("Gender", gender);
      //   },
      // },
      {
        field: "dob",
        headerName: labels?.DOB ?? "DOB",
        getValue({ dateOfBirth }) {
          return dayjs(dateOfBirth).format("MM-DD-YYYY");
        },
      },

      {
        field: "",
        headerName: labels?.update ?? "Update",
        renderCell: () => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [getLkpMatch]
  );

  return (
    <SummaryContent title="Household Composition">
      <TabularView
        sx={{
          table: {
            width: "100%",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0 1.25rem 0.5rem 0",
          },
        }}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

HohInfo.propTypes = {};

export default HohInfo;
