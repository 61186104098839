import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  showSuccessToast,
  showErrorToast,
} from "../../../utils/services/toast";
import Field from "../../../components/Field";
import CoTextField from "../../../controllers/CoTextField";
import Dropdown from "../../../components/Dropdown";
import CDatePicker from "../../../components/DatePicker";
import CCheckBox from "../../../components/checkBox";
import usePageApi from "../../../hooks/usePageApi";
import useLoader from "../../../hooks/useLoader";
import useLookup from "../../../hooks/useLookup";
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import dayjs from "dayjs";
import { find, map } from "lodash";
// import { trimSSN } from "../../../../utils/helpers";
import HttpClient from "../../../utils/HttpClient";
import * as yup from "yup";

export default function AddHouseMember({
  toggle,
  editData,
  selected,
  choosenPrograms,
  screenId,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [showLoader, hideLoader] = useLoader();

  const fieldsObj = {
    0: ["applyPerson", "personLive"],
    1: [
      "firstName",
      "lastName",
      "dob",
      "martialStatus",
      "gender",
      "expDueDate",
    ],
  };

  const onClose = () => {
    toggle();
  };
  //   const onBackOrNext = async (newStep) => {
  //     let valid = false;
  //     valid = await trigger(fieldsObj[currentStep]);
  //     if (valid) {
  //       setCurrentStep(newStep);
  //     } else {
  //       toast.warn("Please provide missing/wrong details");
  //     }
  //   };

  //   const onNext = () => {
  //     if (currentStep < stages.length) {
  //       setCurrentStep(currentStep + 1);
  //     }
  //   };

  //   const onBack = () => {
  //     if (currentStep !== 0) {
  //       setCurrentStep(currentStep - 1);
  //     }
  //   };

  const validationSchema = yup.object({
    firstName: yup.string().required("Please enter first name"),
    lastName: yup.string().required("Please enter last name"),
    dob: yup.string().required("Please select date of birth"),
    ethnicity: yup.string().required("Please select the ethinicity"),
  });

  const {
    control,
    formState: { errors, isValid },
    trigger,
    register,
    watch,
    getValues,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "",
      dob: "",
      race: "",
      ethnicity: "",
      wic: "",
      notApplying: "",
      pregnant: "",
      motherFeeding: "",
      applyingforMembers: "",
    },
  });

  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 206,
    callRetrieveStatus: true,
  });
  const controlId = useSelector(selecttActiveContId) ?? 0;
  const { getOptions } = useLookup("hoh");
  const { individualId = "" } = selected ?? {};

  const data = pageData;
  const memData = find(data, (item) => item?.individualId === individualId);

  const { suffixOpts, ethinicityOpts, raceOpts } = useMemo(() => {
    return {
      suffixOpts: getOptions("Suffix"),
      typeOpts: getOptions("PhoneType"),
      languageOpts: getOptions("PrimaryLanguage"),
      ethinicityOpts: getOptions("Ethnicity"),
      raceOpts: getOptions("Race"),
    };
  }, [getOptions]);

  const onSave = async (data) => {
    const payload = {
      // ...pageData,
      dirtyStatus: editData ? "UPDATE" : "INSERT",
      individualId,
      clearanceStatus: "N",
      screenId: 206,
      controlId,
      status: true,
      importSelected: true,
      editable: false,
      realIndividualId: "",
      isPregnent: getValues("pregnant") === true ? "Y" : "N",
      isBrstFeed: getValues("motherFeeding") === true ? "Y" : "N",
      isApplyMembers: getValues("applyingforMembers") === true ? "Y" : "N",
      isWic: getValues("wic") === true ? "Y" : "N",
      isApplyingForPrograms: getValues("notApplying") === true ? "Y" : "N",
      clientContainer: {
        navigationFlowRequested: false,
        paginationData: {
          resultsPerPage: 5,
          pageNumber: 1,
          totalRecords: 0,
          pageBuffer: 0,
        },
        sourceSystem: "C",
        client: {
          personName: {
            recordId: 0,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            suffix: data.suffix,
          },
          dob: dayjs(data.dob).format("YYYY-MM-DD"),
          // soSec: {
          //   recordId: 0,
          //   soSecPart1: "",
          //   soSecPart2: "",
          //   soSecPart3: "",
          //   ssnVerification: {
          //     dirtyStatus: "",
          //     retryCount: 0,
          //     messages: [],
          //     verificationDocumentCd: "",
          //     verificationRequestedYN: "",
          //     docNames: {},
          //     automatedVerificationYN: "",
          //     documentDetails: [],
          //     verificationType: "",
          //     verifiedYN: "",
          //     verificationSourceCode: "",
          //   },
          //   groupNumber: 0,
          // },
          gender: data.gender,
          race: {
            retryCount: 0,
            recordId: 0,
            primaryRace: data.race,
            otherRaces: ["A"],
            groupNumber: 0,
          },
          headOfHouseholdYN: "N",
          relationshipCd: "",
          maritalStatus: {
            retryCount: 0,
            marriedYN: data.martialStatus,
            recordId: 0,
            groupNumber: 0,
          },
          ethnicity: data.ethnicity,
          pregnantYN: data.areYouPregnant,
          hasSsnYN: "N",
          individualDetails: {
            deathDetails: {
              retryCount: 0,
              state: "",
              deathCertificateNumber: "",
            },
            pregnancy: [
              {
                pregnantYN: data.areYouPregnant,
                pregnancyDueDate: data.expDueDate
                  ? dayjs(data.expDueDate).format("YYYY-MM-DD")
                  : null,
                groupNumber: 0,
              },
            ],
            ethnicity: data.ethnicity,
          },
          disabilities: [],
          earnedIncomes: [],
          unearnedIncomes: [],
          dependentCareExpenses: [],
          unearnedIncomeApplications: [],
          shelterExpenses: [],
          shelterUtilityExpenses: [],
          utilityExpenses: [],
          disabledYN: data.physicallyImpairedYN,
          blindYN: data.visuallyImpairedYN,
          deafYN: data.hearingImpairedYN,
          dobVerification: {
            dirtyStatus: "",
            retryCount: 0,
            messages: [],
            verificationDocumentCd: "",
            verificationRequestedYN: "",
            docNames: {},
            automatedVerificationYN: "",
            documentDetails: [],
            verificationType: "",
            verifiedYN: "",
            verificationSourceCode: "",
          },
          aboutMember: {
            isLiving: data.personLive,
            applyingFor: data.applyPerson,
          },
          fortyQuartersInd: false,
          address: {
            zip4code: data.zipCodePluse,
            vendorId: 0,
            status: true,
            addressLine1: data.line1,
            addressLine2: data.line2,
            city: data.city,
            state: data.state,
            zipcode: data.zipcode,
            county: "",
          },
          createMdmId: true,
        },
        // contactInformation: {
        //   email: "a2@b.com",
        //   preferredModeOfCommunication: "Paperless",
        //   homeAddress: {
        //     zip4code: data.zipCodePluse,
        //     vendorId: 0,
        //     status: true,
        //     addressLine1: data.line1,
        //     addressLine2: data.line2,
        //     city: data.city,
        //     state: data.state,
        //     zipcode: data.zipcode,
        //     county: "",
        //   },
        //   mailingAddress: {
        //     zip4code: data.mailzipCodePluse,
        //     vendorId: 0,
        //     status: true,
        //     addressLine1: data.mailline1,
        //     addressLine2: data.mailline2,
        //     city: data.mailcity,
        //     state: data.mailstate,
        //     zipcode: data.mailzipCode,
        //     county: "",
        //   },
        //   phoneNumberDetails: {
        //     phoneNumber: "",
        //     phoneNumberTypeCd: "",
        //     phoneNumberExtension: "",
        //     altPhoneNumber: "",
        //     altPhoneNumberTypeCd: "",
        //     altPhoneNumberExtension: "",
        //   },
        //   phoneNumberDetailsHstry: [],
        //   phoneNumber: {
        //     phoneNumber: "",
        //     phoneNumberType: "",
        //     phoneNumberExt: "",
        //   },
        //   altPhoneNumber: {
        //     phoneNumber: " ",
        //     phoneNumberType: "",
        //     phoneNumberExt: "",
        //   },
        // },
      },
      screenDefn: "/application/memberInfo/household-Member",
      // studentInfo: {
      //   studentYN: data.isStudentYorN,
      //   effectiveBeginDate: "",
      //   effectiveEndDate: "",
      //   studentStatus: data.studentStatus,
      //   educationLevel: data.educationLevel,
      //   highestGrade: data.lastGrade,
      //   schoolName: data.schoolName,
      //   schoolNumber: data.schoolNumber,
      //   graduationDate: data.gDate
      //     ? dayjs(data.gDate).format("YYYY-MM-DD")
      //     : null,
      //   goodStanding: data.goodstanding,
      //   studentStatusVerification: {
      //     retryCount: 0,
      //   },
      //   goodStandingVerification: {
      //     retryCount: 0,
      //   },
      //   graduationDateVerification: {
      //     retryCount: 0,
      //   },
      //   schoolAddress: {
      //     zip4code: data.zipCodePluse,
      //     vendorId: 0,
      //     status: true,
      //     addressLine1: data.addLine1,
      //     addressLine2: data.addLine2,
      //     city: data.City,
      //     state: data.stateSchl,
      //     zipcode: data.zipCode,
      //   },
      //   groupNumber: 0,
      // },
      alertProceedYN: "Y",
      alertMessage:
        "Failure to provide your SSN may delay the processing of your application.",
      memberCreatedInWp: "N",
      caseId: "",
      populateExistingData: "",
      intakeProgramSelections: map(choosenPrograms || [], (programName) => {
        return {
          dirtyStatus: "",
          individualId,
          clearanceStatus: "",
          controlId: 0,
          status: false,
          importSelected: true,
          editable: true,
          importedFromWp: "",
          programSelected: {
            dirtyStatus: editData ? "update" : "insert",
            programId: 0,
            programName: "LT",
            programSubType: "",
            programStatusCode: "",
            applicationDate: dayjs().format("YYYY-MM-DD"),
            finalizeProgramIndicator: "",
            selectedIndicator: "Y",
            waiverIndicator: "",
            programMode: "",
            confirmProgramIndicator: "",
          },
        };
      }),

      editSsn: true,
    };

    try {
      showLoader();
      const res = await HttpClient.post("/public/consumer/member", payload);
      if (res?.status === 200) {
        showSuccessToast("Member Added Successfully");
        retrieveJson();
        toggle();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (editData) {
      reset({
        firstName: editData
          ? memData?.clientContainer?.client?.personName?.firstName
          : "",
        middleName: editData
          ? memData?.clientContainer?.client?.personName?.middleName
          : "",
        lastName: editData
          ? memData?.clientContainer?.client?.personName?.lastName
          : "",
        suffix: editData
          ? memData?.clientContainer?.client?.personName?.suffix
          : "",
        dob: editData ? memData?.clientContainer?.client?.dob : "",
        race: editData
          ? memData?.clientContainer?.client?.race?.primaryRace
          : "",
        ethnicity: editData ? memData?.clientContainer?.client?.ethnicity : "",
        wic: editData ? (memData?.isWic === "Y" ? true : false) : "",
        notApplying: editData
          ? memData?.isApplyingForPrograms === "Y"
            ? true
            : false
          : "",
        pregnant: editData ? (memData?.isPregnent === "Y" ? true : false) : "",
        motherFeeding: editData
          ? memData?.isBrstFeed === "Y"
            ? true
            : false
          : "",
        applyingforMembers: editData
          ? memData?.isApplyMembers === "Y"
            ? true
            : false
          : "",
      });
    }
  }, [memData]);
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">
          {editData ? "Edit Household member" : "Add Household Member"}
        </Typography>
        <IconButton onClick={toggle}>
          <CloseRounded />
        </IconButton>
      </Stack>

      <Box
        sx={{
          p: "1rem",
          minHeight: "100%",
          mt: "1rem",
        }}
      >
        <Grid container xs={12} spacing={2}>
          <Grid item xs={4}>
            <Field
              name="firstName"
              error={errors.firstName?.message}
              showError={errors.firstName?.message}
              required
              label="First Name"
            >
              <CoTextField
                //   normalizers={[onlyAlphabet, name, maxLength(20)]}
                name="firstName"
                control={control}
                placeholder="Enter First Name"
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="middleName"
              error={errors.middleName?.message}
              showError={errors.middleName?.message}
              label="Middle Name"
            >
              <CoTextField
                //   normalizers={[onlyAlphabet, name, maxLength(20)]}
                name="middleName"
                control={control}
                placeholder="Enter Middle Name"
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="lastName"
              error={errors.lastName?.message}
              showError={errors.lastName?.message}
              required
              label="Last Name"
            >
              <CoTextField
                //   normalizers={[onlyAlphabet, name, maxLength(20)]}
                name="lastName"
                control={control}
                placeholder="Enter Last Name"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="suffix" label="Suffix">
              <Dropdown name="suffix" control={control} options={suffixOpts} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              error={errors.dob?.message}
              showError={errors.dob?.message}
              name="dob"
              label="DOB"
              required
            >
              <CDatePicker
                name="dob"
                control={control}
                placeholder="Select Date(MM/DD/YYYY)"
                disableFuture
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="race" label="Race">
              <Dropdown name="race" control={control} options={raceOpts} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              error={errors.ethnicity?.message}
              showError={errors.ethnicity?.message}
              name="Ethnicity"
              label="Ethnicity"
              required
            >
              <Dropdown
                name="ethnicity"
                control={control}
                options={ethinicityOpts}
              />
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">
              {/* {labels?.languageHelp ?? "Select Which Program you are requesting:"} */}
              {"Select which program you are requesting:"}
            </Typography>
            <CCheckBox
              name="wic"
              control={control}
              label={"Woman Infants, & Children(WIC)"}
            />
          </Grid>
          <Grid item xs={12}>
            <CCheckBox
              name="notApplying"
              control={control}
              label={"Not Applying for Programs"}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">
              {/* {labels?.languageHelp ?? "Select Which Program you are requesting:"} */}
              {"You are:"}
            </Typography>
            <CCheckBox name="pregnant" control={control} label={"Pregnant"} />
          </Grid>
          <Grid item xs={12}>
            <CCheckBox
              name="motherFeeding"
              control={control}
              label={"Mother Breastfeeding"}
            />
          </Grid>

          <Grid item xs={12}>
            <CCheckBox
              name="motherNotFeeding"
              control={control}
              label={"Mother Not Breastfeeding"}
            />
          </Grid>
          <Grid item xs={12}>
            <CCheckBox
              name="applyingforMembers"
              control={control}
              label={"Applying for other household members"}
            />
          </Grid>
        </Grid>

        <Stack direction="row-reverse" mr="1rem" spacing={2}>
          <Button
            size="large"
            sx={{ mr: "0.5rem" }}
            variant="outlined"
            onClick={() => onClose()}
          >
            Close
          </Button>

          <Button
            type="submit"
            size="large"
            // disabled={!errors}
            variant="contained"
            onClick={handleSubmit(onSave)}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
}
