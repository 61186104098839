import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";
import { ScreenContext } from "../../containers/ScreenProvider";

function Field({
  name,
  label,
  defaultLabel,
  required,
  error,
  errorText,
  fullWidth,
  children,
  sx,
  showError,
  titleVariant,
}) {
  const { screenId, labels } = useContext(ScreenContext) ?? {};

  return (
    <FormControl
      error={error}
      fullWidth={fullWidth}
      sx={{ position: "relative", ...sx }}
    >
      <FormLabel sx={{ color: "#000000", textAlign: "left", mb: "0.25rem" }}>
        <Typography variant={titleVariant}>
          {labels?.[name] ?? label}
          {required && <span style={{ color: "red" }}>*</span>}
        </Typography>
      </FormLabel>
      {children}
      {error && showError && (
        <FormHelperText
          sx={{
            ml: 0,
            position: "absolute",
            bottom: "-1.5rem",
            //  position: "absolute",
            // bottom: "-1.5rem",
          }}
          error
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

Field.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  formik: PropTypes.shape({
    getFieldProps: PropTypes.func,
    onChange: PropTypes.func,
  }),
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  errorText: PropTypes.string,
  showError: PropTypes.bool,
  defaultLabel: PropTypes.string,
  titleVariant: PropTypes.string,
};

Field.defaultProps = {
  name: "",
  label: "unknown",
  fullWidth: true,
  date: false,
  children: "",
  errorText: "",
  customHandler: false,
  showError: true,
  defaultLabel: "",
  titleVariant: "h5grey",
};

export default Field;
