import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadFile from "../../components/FileUpload";
import { filter, find } from "lodash";

export default function DocumentTable({
  columnsData,
  row,
  getName,
  selected,
  api,
}) {
  const [openDocModal, setOpenDocModal] = useState(false);
  const { dummyIndividualId } = selected;
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const individualData = filter(
      row,
      (item) => item?.individualId === Number(dummyIndividualId)
    );
    if (individualData) {
      setRowData(individualData);
    }
  }, [row, dummyIndividualId]);

  useEffect(() => {
    api();
  }, [dummyIndividualId]);

  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="flex-end">
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={() => setOpenDocModal(true)}
          >
            Upload files
          </Button>
        </Stack>
        <DataGrid
          rows={rowData}
          columns={columnsData}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "18px",
              fontWeight: "700",
              padding: "0px 10px",
              color: "#000000",
            },
            "& .MuiDataGrid-row": {
              fontSize: "16px",
              color: "black",
              padding: "0px 10px",
              textAlign: "center",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
        />
      </Grid>
      <UploadFile
        docOpen={openDocModal}
        setDocOpen={setOpenDocModal}
        defaultDocType={""}
        getName={getName}
        activeIndex={dummyIndividualId}
        subTypeOptions={[]}
      />
    </>
  );
}
