import React, { useEffect } from "react";
import PageContainer from "../../components/PageContainer";
import { Grid, Typography, Box } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../components/YesNoOption";
import CCheckBox from "../../components/checkBox";
import { showErrorToast } from "../../utils/services/toast";
import usePageApi from "../../hooks/usePageApi";
import usePageNav from "../../hooks/usePageNav";
import useLoader from "../../hooks/useLoader";
import useDynamicNav from "../../hooks/useDynamicNav";

function WicProgram({ screenId }) {
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const { handleNext } = useDynamicNav({ screenId });

  const onBack = () => {
    navigations.toAdditionalIncom();
  };

  const {
    watch,
    control,
    getValues,
    setValue,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(userShema),
    defaultValues: {
      isEnrolled: "",
      isEnrolledPrg: "",
      medicaid: "",
      fap: "",
      tanf: "",
      fip: "",
      fdpir: "",
      free: "",
      otherState: "",
      form: "",
      reading: "",
      wheel: "",
      hearing: "",
      mentally: "",
      physical: "",
      speech: "",
      visually: "",
      other: "",
    },
  });
  const isEnrolled = watch("isEnrolled") === "Y";
  const isEnrolledPrg = watch("isEnrolledPrg") === "Y";

  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId,
    callRetrieveStatus: true,
  });

  const convertToYN = (value) => (value ? "Y" : "N");
  const convertToTF = (value) => (value === "Y" ? true : false);

  useEffect(() => {
    reset({
      isEnrolled: pageData?.isEnrolled,
      isEnrolledPrg: pageData?.isEnrolledPrg,
      medicaid: convertToTF(pageData?.medicaid),
      fap: convertToTF(pageData?.fap),
      tanf: convertToTF(pageData?.tanf),
      fip: convertToTF(pageData?.fip),
      fdpir: convertToTF(pageData?.fdpir),
      free: convertToTF(pageData?.free),
      otherState: convertToTF(pageData?.otherState),
      form: convertToTF(pageData?.form),
      reading: convertToTF(pageData?.reading),
      wheel: convertToTF(pageData?.wheel),
      hearing: convertToTF(pageData?.hearing),
      mentally: convertToTF(pageData?.mentally),
      physical: convertToTF(pageData?.physical),
      speech: convertToTF(pageData?.speech),
      visually: convertToTF(pageData?.visually),
      other: convertToTF(pageData?.other),
    });
  }, [pageData, reset]);

  const onSave = async () => {
    const payloay = {
      isEnrolled: getValues("isEnrolled"),
      isEnrolledPrg: getValues("isEnrolledPrg"),
      medicaid: convertToYN(getValues("medicaid")),
      fap: convertToYN(getValues("fap")),
      tanf: convertToYN(getValues("tanf")),
      fip: convertToYN(getValues("fip")),
      fdpir: convertToYN(getValues("fdpir")),
      free: convertToYN(getValues("free")),
      otherState: convertToYN(getValues("otherState")),
      form: convertToYN(getValues("form")),
      reading: convertToYN(getValues("reading")),
      wheel: convertToYN(getValues("wheel")),
      hearing: convertToYN(getValues("hearing")),
      mentally: convertToYN(getValues("mentally")),
      physical: convertToYN(getValues("physical")),
      speech: convertToYN(getValues("speech")),
      visually: convertToYN(getValues("visually")),
      other: convertToYN(getValues("other")),
    };
    try {
      showLoader();
      const res = await saveJson(payloay);
      if (res.status === 200) {
        navigations.toFileupload();
        handleNext();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <Box sx={{ paddingLeft: "1rem" }}>
      <PageContainer
        title="Because you are applying for WIC, please answer the following"
        onBack={onBack}
        onNext={handleSubmit(onSave)}
      >
        <Typography variant="h6" my={2}>
          Is anyone in your household enrolled in any of the following programs:
        </Typography>
        <Controller
          name="isEnrolled"
          control={control}
          render={({ field }) => {
            return <YesNoOption {...field} />;
          }}
        />

        {isEnrolled && (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <CCheckBox name="medicaid" control={control} label="Medicaid" />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="fap"
                control={control}
                label="Food Assistance Program"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="tanf"
                control={control}
                label="Temporary Assistance for Needy Families (TANF)"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="fip"
                control={control}
                label="Family Independence Program (FIP)"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="fdpir"
                control={control}
                label="Food Distribution Program on Indian Reservations (FDPIR)"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="free"
                control={control}
                label="Free or Reduced - Price School Lunch"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="otherState"
                control={control}
                label="Other State-Provided Health Insurance"
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6" my={2}>
          What type of disablity or additional accomodations does your household
          have?
        </Typography>
        <Controller
          name="isEnrolledPrg"
          control={control}
          render={({ field }) => {
            return <YesNoOption {...field} />;
          }}
        />
        {isEnrolledPrg && (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <CCheckBox
                name="form"
                control={control}
                label="Forms Assistance"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="reading"
                control={control}
                label="Reading Assistance"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="wheel"
                control={control}
                label="Wheelchair Assistance"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="hearing"
                control={control}
                label="Hearing Impaired"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="mentally"
                control={control}
                label="Mentally Challenged"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="physical"
                control={control}
                label="Physical Disability"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="speech"
                control={control}
                label="Speech Impaired"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox
                name="visually"
                control={control}
                label="Visually Impaired"
              />
            </Grid>
            <Grid item xs={12}>
              <CCheckBox name="other" control={control} label="Other" />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Box>
  );
}

export default WicProgram;
