import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Grid, IconButton, Tooltip } from "@mui/material";

function CitizenshipSts({ houseMembers, getLkpMatch, labels, summaryData }) {
  const columns = useMemo(
    () => [
      {
        field: "",
        headerName: labels?.citiStatus ?? "Citizenship Status",

        getValue({ inCitizenshipDetails = {} }) {
          const citizen = getLkpMatch(
            "Citizenship",
            inCitizenshipDetails?.citizanship
          );
          return `${citizen ?? "-"} `;
        },

        // value: getLkpMatch(
        //   "Citizenship",
        //   summaryData?.inCitizenshipDetails?.citizanship
        // ),
      },
      {
        field: "",
        headerName: labels?.phone ?? "Registered to vote",
        getValue({ inCitizenshipDetails = {} }) {
          const citizen = getLkpMatch(
            "YesNo",
            inCitizenshipDetails?.voteRegistered
          );
          return `${citizen ?? "-"} `;
        },
      },
    ],
    [getLkpMatch, summaryData]
  );
  return (
    <SummaryContent title={labels?.citizenInfo ?? "Citizenship Status"}>
      <TabularView
        sx={{
          table: {
            width: "100%",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0 1.25rem 0.5rem 0",
          },
        }}
        columns={columns}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        data={[summaryData]}
      />
    </SummaryContent>
  );
}

CitizenshipSts.propTypes = {};

export default CitizenshipSts;
