import { useSelector } from "react-redux";
import { useNavigate, useParams, useMatch } from "react-router";
import { selectNavLinks } from "../store/selectors/navigationSelector";
import { find, findIndex, flatten, map, noop, some } from "lodash";
import { useMemo } from "react";
import { selecttActiveContId } from "../store/selectors/appSelector";
import HttpClient from "../utils/HttpClient";

function useDynamicNav(props = {}) {
  const { screenId } = props;
  const id = screenId;
  const navigate = useNavigate();
  const onBack = () => navigate(-1);
  const navLinks = useSelector(selectNavLinks);
  const controlId = useSelector(selecttActiveContId);
  const { prevPage, nextPage, moduleId } = useMemo(() => {
    const currentModule = find(navLinks, ({ subNavs }) =>
      some(subNavs, ({ screenId }) => {
        return id === screenId;
      })
    );
    console.log(currentModule, "currentModule");

    const subNavs = map(navLinks, ({ subNavs }) => subNavs);
    const flattenNavs = flatten(map(subNavs));
    const currentScreenIndex = findIndex(flattenNavs, { screenId: id });
    const prevPage = flattenNavs?.[currentScreenIndex - 1]?.screenDefn;
    const nextPage = flattenNavs?.[currentScreenIndex + 1]?.screenDefn;

    return {
      prevPage,
      nextPage,
      moduleId: currentModule?.moduleId,
    };
  }, [navLinks, id]);

  const saveScreenStatus = async (cb = noop, filled = true) => {
    try {
      const res = await HttpClient.post("/uiLable/pageModuleStatus/create", {
        controlId,
        moduleId,
        screenId,
        screenCompletedCount: filled ? "1" : "0",
      });
      if (res.status === 200) {
        cb();
      }
      return res;
    } catch (error) {
      //
    } finally {
      //
    }
  };

  const handleNext = (filled) => {
    saveScreenStatus(() => navigate(nextPage), filled);
  };

  return {
    onBack,
    prevPage,
    nextPage,
    moduleId,
    handleNext,
  };
}

export default useDynamicNav;
