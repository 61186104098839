import { all, takeEvery, put } from "redux-saga/effects";
import HttpClient, { authApi } from "../../utils/HttpClient";
import { showErrorToast } from "../../utils/services/toast";
import { groupBy, map, partition, sortBy, values, first } from "lodash";
import { setNavLinks } from "../slices/navigationSlice";
import { FETCH_NAV_OPTIONS } from "../types/navTypes";
import navMock from "./navMock.json";
import { fetchNavReq } from "../../services/apiRequests";
import { useEffect } from "react";

const getNavLinks = (navUrls) => {
  if (navUrls.length) {
    const navGroups = groupBy(navUrls, (url) => url.prntDesc);
    const navs = values(navGroups).map((navArray) => {
      const [parentNav, subNavs] = partition(navArray, (nav) => nav.isParent);
      const sortedSubNavs = sortBy(subNavs, (nav) => nav.sortOrderNbr);
      const sortedSubNavsWithUrls = map(sortedSubNavs, (nav) => ({
        ...nav,
        nav_url: "",
        nav_icon: "CaseDocuments",
      }));

      return {
        ...first(parentNav),
        nav_url: "",
        nav_icon: "CaseDocuments",
        subNavs: sortedSubNavsWithUrls,
      };
    });
    return sortBy(navs, (nav) => nav.sortOrderNbr);
  }
  return [];
};

export function* fetchNavOptions(action) {
  try {
    // const { caseId, pageType = "R" } = action.payload;
    const payload={
      localeCode:"en_us",
      appType:"AP"
  }
    const res= yield fetchNavReq(payload)
    if (res.status===200) {
      console.log(res);
    const navLinks = yield put(setNavLinks(getNavLinks(res?.data)));
    }
    // const res = fetchNavReq()
    // if (res?.status === 200) {
    //   yield put(setAuthToken('token'));
    // }
  } catch (error) {
    console.log("error", error);
  }
}

export default function* watchNavigations() {
  yield all([takeEvery(FETCH_NAV_OPTIONS, fetchNavOptions)]);
}
