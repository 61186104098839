import { useState, useEffect, useMemo, useCallback } from "react";
import LookUpPayload from "../constants/lookup";
import HttpClient from "../utils/HttpClient";
import mock from "./mock.json";
import { curry, first, isArray, isObject, map } from "lodash";

function useLookup(pageName = "", alterProps = {}) {
  const [lookup, setLookup] = useState(mock);
  const [lookupFailed, setLookupFailed] = useState(false);
  //   const {
  //     pageName = 'CaseHome',
  //     attr: { keyProp = 'lookupDescription', valueProp = 'lookupLongValue' },
  //   } = props ?? { attr: {} };

  const fetchLookup = async () => {
    try {
      const res = await HttpClient.post(
        "/public/consumer/lookup-search",
        LookUpPayload[pageName]
      );
      if (res.status === 200) {
        setLookup(res.data);
      } else {
        throw new Error("Lookup fetch failed");
      }
    } catch (error) {
      setLookupFailed(true);
    }
  };

  const flatten = useMemo(() => {
    const { keyProp = "lookupDescription", valueProp = "lookupLongValue" } =
      alterProps;
    let resultObj = {};
    for (const key of Object.keys(
      // LookUpPayload[pageName]
      lookup
    )) {
      let lookupMatches = {};

      for (const lkp of lookup[key] ?? []) {
        lookupMatches[lkp[keyProp]] = lkp[valueProp];
      }
      resultObj[key] = lookupMatches;
    }

    return resultObj;
  }, [alterProps, lookup, pageName]);

  useEffect(() => {
    if (pageName) {
      fetchLookup();
    }
  }, []);

  const getLookup = useCallback(
    (lkpName, key) => {
      const match = flatten[lkpName]?.[key] ?? key;
      // if (!match) {
      //   debugger;
      // }
      return match;
    },
    [flatten]
  );

  const getOptions = (lkpName, _alterProps = {}) => {
    const { keyProp = "lookupDescription", valueProp = "lookupLongValue" } =
      _alterProps;
    const lkpValue = lookup[lkpName];
    if (!lkpValue) return [];

    if (isArray(lkpValue) && isObject(first(lkpValue))) {
      return map(lkpValue, (lkp) => ({
        label: lkp[valueProp],
        value: lkp[keyProp],
      }));
    } else {
      return lkpValue;
    }
  };

  return {
    islkpFailed: lookupFailed,
    lkpRaw: lookup,
    lkpFlatten: flatten,
    getOptions,
    getLkpMatch: curry(getLookup),
  };
}

export default useLookup;
