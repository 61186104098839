export const LOOKUP_SEARCH = "/public/consumer/lookup-search";
export const JSON_RETRIEVE = "/public/consumer/jsonRetrieve";
export const CASE_INITIATIVE = "/public/consumer/case_initiate";
export const ADDRESS_INFO = "/verification/200000000/address";
export const MEMBER_CALL = "/public/consumer/member";
export const JSON_SAVE = "/public/consumer/jsonSave";
export const LEFT_NAV_GETCALL = "/uiLable/module/statusRetrieve/";
export const LEFT_NAV_POSTCALL = "/uiLable/pageModuleStatus/create";
export const FETCH_NAVIGATION = "/public/consumer/rc-navigation";
export const SEARCH_CASE = "/case-management/cp_redet/consumer-control-search";
export const SCREEN_LABELS = `/uiLable/screenLables/{0}/{1}/CP`;
export const VENDOR_SEARCH = "/application-process/inquiry/vendor-search";
export const SUMMARY_INFO = "/case-management/cp_case_creation_dataview/";
export const CONSUMER_SUBMIT = "/public/consumer/submit/";
export const SAVE_SUBMIT_DETILS =
  "/application-process/consumer/save-submit-details";
export const CASE_DELETION = "/public/consumer/update/";
export const RETRIEVE_RELATION = "/public/consumer/retrieve-relationships/";
// export const DOWNLOAD_PDF = "/case-management/cp_9701_download/";
// export const GET_UPLOAD_FILES = `/application-process/screening/cp/documents/{0}`;
// export const POST_UPLOAD_FILES = `http://34.200.146.243:8080/case-management/document/{0}/upload`;

export const SMARTY_STREET = "/verification/address";
export const RELATIONSHIP_MEMBERDETAILS =
  "/public/consumer/retrieve-relationships/{0}";
export const FILE_UPLOADER = `/case-management/document/{0}/upload`;

export const GET_SUMMARY = "/case-management/cp_case_creation_dataview/{0}";

export const SUBMITEDCASE = "/public/consumer/submit/{0}/AP";

export const SIGNSAVE = "/public/consumer/jsonSave/{0}/168";

export const PDFGEN = "/case-management/cp_9701_download/{0}";
export const CASE_INITIATE = "/public/consumer/case_initiate";
export const APPLY_BENEFITS = `/public/consumer/jsonSave/{0}/146`;
export const MEMBER = `/public/consumer/member`;
export const REAL_TIME_CASE_STATUS = `/public/consumer/real-time-case-status/{0}`;
