import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { useDebugValue, useEffect, useMemo, useState } from "react";
import SummaryItem from "./SummaryItem";
import CaseInfo from "./CaseInfo";
import useDynamicNav from "../../hooks/useDynamicNav";
import ContactInfo from "./ContactInfo";
import HohInfo from "./HohInfo";
import RaceEthni from "./RaceEthnicity";
import CitizenshipSts from "./Citizenship";
import EarnedInc from "./EarnedIncome";
import UnEarnedApp from "./UnearnedApp";
import UnEarned from "./Unearned";
import HttpClient from "../../utils/HttpClient";
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../../store/selectors/appSelector";
import useLookup from "../../hooks/useLookup";
import useLoader from "../../hooks/useLoader";
import PageContainer from "../../components/PageContainer";
import usePageNav from "../../hooks/usePageNav";

function CaseSummary({ screenId }) {
  const [summaryData, setSummaryData] = useState("");
  const { getLkpMatch } = useLookup("caseSummary");
  const activeControlId = useSelector(selecttActiveContId);
  const [showLoader, hideLoader] = useLoader();
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });

  const getSummaryDetails = async (value) => {
    showLoader();
    try {
      const res = await HttpClient.get(
        `case-management/cp_case_creation_dataview/${value}`
      );
      if (res?.data) {
        console.log(res?.data, "response");
        setSummaryData(res?.data);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  console.log(summaryData, "sd");
  useEffect(() => {
    getSummaryDetails(activeControlId);
  }, [activeControlId]);

  const {
    hohData,
    memberInfo,
    contactInfo,
    MediExpenses,
    striker,
    hohCompostion,
    citizen,
    authRep,
    earned,
    unEarned,
    earnedApp,
    Shelter,
    assets,
    court,
    contactInfomation,
    raceDis,
  } = useMemo(() => {
    const {
      inhomePage,
      inMembersMemberInfo,
      inHeadOfHouseholdADDRESS,
      inHeadOfHouseholdCitizenshipImmigration,
      inHeadOfHouseholdauthReo,
      inFinancialMediExpenses,
      inFinancialStriker,
      inFinancialEarned,
      inFinancialUnearned,
      inFinancialunearnedApps,
      inFinancialShelterUtility,
      inFinancialAssets,
      inFinancialCourtOrder,
      inHeadOfHouseholdHohInfo,
      inHeadOfHouseholdContactInfo,
      inRaceDisability,
    } = summaryData;
    return {
      hohData: inHeadOfHouseholdHohInfo,
      hohCompostion: inhomePage,
      memberInfo: inMembersMemberInfo,
      contactInfo:
        summaryData?.inContactInfo?.[0]?.contactInformationDetails
          ?.contactInformation?.homeAddress ?? {},
      contactInfomation:
        inHeadOfHouseholdContactInfo?.[0]?.contactInformationDetails
          ?.contactInformation,
      citizen: inHeadOfHouseholdCitizenshipImmigration,
      authRep: inHeadOfHouseholdauthReo,
      MediExpenses: inFinancialMediExpenses,
      striker: inFinancialStriker,
      earned: inFinancialEarned,
      unEarned: inFinancialUnearned,
      earnedApp: inFinancialunearnedApps,
      Shelter: inFinancialShelterUtility,
      assets: inFinancialAssets,
      court: inFinancialCourtOrder,
      raceDis: inRaceDisability,
    };
  }, [summaryData]);
  const onBack = () => {
    navigations.toFileupload();
  };
  const onNext = () => {
    navigations.toSignature();
    handleNext()
  };
  return (
    <PageContainer title="Summary" onNext={onNext} onBack={onBack}>
      <div>
        <SummaryItem
          defaultOpen
          title={
            // labels?.caseInfoAndContactInfo ??
            "Case Information and Contact Information"
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <CaseInfo
                hohData={hohData}
                // labels={labels}
                memberInfo={memberInfo}
                // raceDis={raceDis}
                getLkpMatch={getLkpMatch}
                summaryData={summaryData}
              />
            </Grid>
            <Grid item xs={6}>
              <ContactInfo
                summaryData={summaryData}
                // labels={labels}
                contactInfo={contactInfo}
                contactInfomation={contactInfomation}
                raceDis={raceDis}
                hohData={hohData}
                citizen={citizen}
                getLkpMatch={getLkpMatch}
              />
            </Grid>
          </Grid>
        </SummaryItem>

        <SummaryItem
          title={
            // labels?.householdCompAndRaceEthnicity ??
            "Household Composition and Race & Ethnicity"
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <HohInfo
                summaryData={summaryData}
                getLkpMatch={getLkpMatch}
                houseMembers={hohCompostion}
                // labels={labels}
              />
            </Grid>
            <Grid item xs={4.5}>
              <RaceEthni
                getLkpMatch={getLkpMatch}
                houseMembers={memberInfo}
                // labels={labels}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem
          title={
            // labels?.citizenshipStatusAndAuthRep ??
            "Citizenship Status"
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CitizenshipSts
                summaryData={summaryData}
                getLkpMatch={getLkpMatch}
                houseMembers={citizen}
                // labels={labels}
              />
            </Grid>
            {/* <Grid item xs={4.5}>
            <AuthRep getLkpMatch={getLkpMatch} houseMembers={authRep} />
          </Grid> */}
          </Grid>
        </SummaryItem>
        <SummaryItem
          title={
            // labels?.earnedIncomeAndShelterExp ??
            "Employment"
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EarnedInc
                getLkpMatch={getLkpMatch}
                houseMembers={earned}
                summaryData={summaryData}
                // labels={labels}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem title="Additional Income">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UnEarned
                summaryData={summaryData}
                getLkpMatch={getLkpMatch}
                houseMembers={unEarned}
                // labels={labels}
              />
            </Grid>
            {/* <Grid item xs={4.5}>
            <UnEarnedApp
              getLkpMatch={getLkpMatch}
              houseMembers={earnedApp}
              // abels={labels}
            />
          </Grid> */}
          </Grid>
        </SummaryItem>
      </div>
    </PageContainer>
  );
}

export default CaseSummary;
