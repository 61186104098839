import React, { useEffect, useMemo } from "react";
import ScreenProvider from "../../containers/ScreenProvider";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import PageContainer from "../../components/PageContainer";
import usePageApi from "../../hooks/usePageApi";
import Field from "../../components/Field";
import Dropdown from "../../components/Dropdown";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../components/YesNoOption";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import useLookup from "../../hooks/useLookup";
import useLoader from "../../hooks/useLoader";
import usePageNav from "../../hooks/usePageNav";
import { showErrorToast } from "../../utils/services/toast";
import { isEmpty } from "lodash";
import useDynamicNav from "../../hooks/useDynamicNav";

const CitizenshipDetails = ({ screenId }) => {
  const { pageData, labels, saveJson } = usePageApi({
    screenId,
    callRetrieveStatus: true,
  });
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const { handleNext } = useDynamicNav({ screenId });
  const { getOptions } = useLookup("Citizenship");

  const {
    watch,
    control,
    getValues,
    setValue,
    reset,
    //errors,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    // resolver: yupResolver(userSchema),

    defaultValues: {},
  });

  const { citizenship } = useMemo(() => {
    return {
      citizenship: getOptions("Citizenship"),
    };
  }, [getOptions]);

  const onSave = async (value) => {
    const payload = {
      citizanship: value?.citizanship,
      voteRegistered:
        watch("citizanship") === "N" || watch("citizanship") === "C"
          ? value.voteRegistered
          : null,
    };
    try {
      showLoader();
      const res = await saveJson(payload);
      if (res.status === 200) {
        navigations.toRequestedProgram();
        handleNext();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (isEmpty(pageData)) {
    } else {
      reset(pageData);
    }
  }, [pageData]);

  const onBack = () => {
    navigations.toContactInfo();
  };

  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="Citizenship Details"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Grid
            container
            spacing={3}
            sx={{
              minWidth: "30.6rem",
              maxWidth: "80%",
              paddingTop: "0.5rem",
            }}
          >
            <Grid item>
              <Field label="Could you confirm your citizenship status, please?">
                <Typography>Citizenship Status</Typography>

                <Dropdown
                  name="citizanship"
                  control={control}
                  placeholder="Select Citizenship"
                  options={citizenship}
                />
              </Field>
            </Grid>
            {(watch("citizanship") === "N" || watch("citizanship") === "C") && (
              <>
                <Grid item>
                  <Field
                    name="voteRegistered"
                    label="If you are not registered to vote where you live now, would you like to apply to 
register to vote here today?"
                    //   required
                    //   error={errors?.firstName?.message}
                    //   showError={errors?.firstName?.message}
                  >
                    <Controller
                      name="voteRegistered"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} name="voteRegistered" />;
                      }}
                    />
                  </Field>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{ color: "var(--grey-400)", fontSize: "18px" }}
                  >
                    If you do not check any box you will be considered to have
                    decided to not register to vote at this time, but a{" "}
                    <Typography variant="subtitle1">
                      paper voter registration application form will be mailed
                      to you should you decide to register or update your
                      registration.
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{ color: "var(--grey-400)", fontSize: "18px" }}
                  >
                    Applying to register or declining to register to vote will
                    not affect the amount of assistance that you will be
                    provided, or your eligibility. Your decision to register to
                    vote or not will be kept confidential. If you would like
                    help filling out the voter registration application, we will
                    help you or you can call the Secretary of State toll-free at
                    888-SOS-MICH; 888-767-6424 for assistance. The decision to
                    seek or accept help is yours. You may also fill out the
                    application in private
                  </Typography>
                  <Typography
                    sx={{ color: "var(--grey-400)", fontSize: "18px" }}
                  >
                    If you believe that someone has interfered with your right
                    to register or to decline to register to vote, or your right
                    to privacy in deciding whether to register; you may file a
                    complaint with the:
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    color: "var(--grey-400)",
                    display: "flex",
                  }}
                >
                  <Stack>
                    <FmdGoodOutlinedIcon />
                  </Stack>
                  <Stack>
                    <Typography variant="h4">
                      Michigan Department of State: Richard H. Austin Building
                      430 W. Allegan, Lansing, MI 48918 toll-free at
                      888-SOS-MICH; 888-767-6424
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle">
                    You can also register to vote online at
                    <Link> www.michigan.gov/sos</Link> or
                    <Link>click here</Link> for a printable voter registration
                    application.
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </PageContainer>
      </Box>
    </ScreenProvider>
  );
};

export default CitizenshipDetails;
