import React, { useEffect, useState } from "react";
import { Grid, Box, Paper, AppBar } from "@mui/material";
import Button from "@mui/material/Button";
import "./LandingPage.css";
import carepic from "../../assets/images/caregrouppic_new.png";
import child from "../../assets/images/littlekid.svg";
import cash from "../../assets/images/Holdingmoney.svg";
import medicaid from "../../assets/images/medicalimg.svg";
import snap from "../../assets/images/Snapimg.svg";
import energy from "../../assets/images/Eleimg.svg";
// import { Explore, Program, Help } from "../ConsumerDashboard/Menu";
// import Footer from "../ConsumerDashboard/Footer";
// import kcare from "./images/kcarelogonew.png";
// import kyyba from "../ConsumerDashboard/images/Kcares.png";

// card data th gridv
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import arrow from "../../assets/images/arrow.svg";

import viewbenefit from "../../assets/images/view.svg";
import track from "../../assets/images/track.svg";
import submit from "../../assets/images/submit.svg";
import call from "../../assets/images/call.svg";
import location from "../../assets/images/location.svg";
import online from "../../assets/images/online.svg";
import { useNavigate } from "react-router-dom";
// import { fetchData, SCREEN_LABELS } from "../../services/api";
import usePageApi from "../../hooks/usePageApi";
import Footer from "../../containers/Layout/Footer";
import NavBar from "../../containers/Layout/NavBar";
import usePageNav from "../../hooks/usePageNav";

const LandingPage = () => {
  const { navigations } = usePageNav();

  // const [labels, setScreenLabels] = useState({});
  const [language, setLanguage] = useState(
    localStorage.getItem("language_code") || "en_US"
  );
  //   const { screenLabels, fetchScreenLables } = useScreenLabels(426);
  const { labels, pageData, saveJson, retrieveJson, dirtyFields } = usePageApi({
    // controlId: controlId,
    screenId: 426,
    screenDefn: "/dashboard/consumer-app/intake-app/members/relationship",
  });
  const [languageChanged, setLanguageChanged] = useState(false);
  useEffect(() => {
    // handleScreenLables();
    localStorage.setItem("language_code", language);
  }, [language]);

  const handleLanguage = () => {
    const newLanguage = language === "en_US" ? "en_ES" : "en_US";
    setLanguage(newLanguage);
    localStorage.setItem("language_code", newLanguage);
    // fetchScreenLables(newLanguage);
    setLanguageChanged(true);
  };

  const dashboard = () => {
    navigations.toDashboard();
  };
  const createAccount = () => {
    navigations.toRegistration();
  };

  return (
    <React.Fragment>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <NavBar />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container className="landsecondgridmain">
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={10}>
                    <div className="landsecondgridleft">
                      <span className="kacre-k">{labels?.k ?? "K"}</span>
                      <span className="kcare-care">
                        {labels?.cares ?? "Connect"}
                      </span>
                      <span className="simplifies">
                        {labels?.simplifies ?? "Simplifies"}
                      </span>
                      <span className="benefitapp">
                        {labels?.kcares ?? "Benefit Application & Management"}
                      </span>
                    </div>
                  </Grid>
                  <div className="apply-create">
                    <Button
                      variant="contained"
                      className="applybenefit"
                      onClick={() => dashboard()}
                    >
                      <span className="applybenefittext">
                        {labels?.applyForBenefits ?? "Apply for Benefits"}
                      </span>
                    </Button>
                    <Button
                      variant="text"
                      className="createacc"
                      onClick={() => createAccount()}
                    >
                      <span className="createacctext">
                        {labels?.createAnAccount ?? "Create an Account"}
                      </span>
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  // backgroundColor: "#44A0E3",
                  // borderRadius: "20px 0px 0px 20px",
                  backgroundImage: `url(${carepic})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "right",
                }}
              >
                {/* <img src={carepic} alt="" className="imggroup" /> */}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid xs={12} sm={12} lg={12} md={12}>
            <div className="landprogram"></div>
          </Grid> */}
          <Grid
            item
            xs={12}
            sx={{ backgroundColor: "#E6ECF1", paddingBottom: "1rem" }}
          >
            <div className="landthirdgridmain" style={{ maxwidth: "100%" }}>
              <div className="childsupp">
                <div className="childcare">
                  {labels?.ccs ?? "Child Care Support"}
                </div>
                <img
                  src={child}
                  alt=""
                  style={{
                    width: "260px",
                    height: "auto",
                    maxWidth: "100%",
                    paddingLeft: "2px",
                  }}
                />
              </div>
              <div className="cashass">
                <div className="childcare">
                  {labels?.ca ?? "Cash Assistance"}
                </div>
                <img
                  src={cash}
                  alt=""
                  style={{
                    width: "260px",
                    height: "auto",
                    maxWidth: "100%",
                    paddingRight: "2px",
                  }}
                />
              </div>
              <div className="snapland">
                <div className="childcare">{labels?.snap ?? "SNAP"}</div>
                <Card
                  sx={{ maxWidth: 260 }}
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <CardMedia sx={{ height: 220 }} image={snap} title="snap" />
                  <CardContent className="cardcontent">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="typotext"
                    >
                      {labels?.snapDetails ??
                        "SNAP provides food benefits to low-income families to supplement their grocery budget so they can afford the nutritious food essential health and well-being."}
                    </Typography>
                  </CardContent>
                  <CardActions className="cardbutton">
                    <Button size="small">
                      {labels?.learnMore ?? "Learn More"}
                    </Button>
                    <img src={arrow} alt="" className="arr" />
                  </CardActions>
                </Card>
              </div>

              <div className="medicaidland">
                <div className="childcare">
                  {labels?.medicaid ?? "Medicaid"}
                </div>
                <img
                  src={medicaid}
                  alt=""
                  style={{ width: "260px", height: "auto" }}
                />
              </div>
              <div className="energyland">
                <div className="childcare">
                  {labels?.ea ?? "Energy Assistance"}
                </div>
                <img
                  src={energy}
                  alt=""
                  style={{ width: "260px", height: "auto" }}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={12} lg={12}>
                  <div className="manage-benefit">
                    {labels?.manageBenefits ?? "I Want to Manage My Benefits."}
                  </div>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Grid container>
                    <Paper className="parentbenefit">
                      <Grid item xs={4}>
                        <div className="view-benefit">
                          <img
                            src={viewbenefit}
                            alt=""
                            style={{ width: "120px", height: "120px" }}
                          />
                          <div className="submittext">
                            {labels?.screenDetail?.JSON.parse(
                              labels.screenDetail
                            )?.viewBenefits ?? "View your Benefits Details"}
                          </div>
                          <div className="submitsubtext">
                            {labels?.benefitDetail ??
                              "This includes program status, benefit amount, and  other case details."}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="view-benefit">
                          <img
                            src={track}
                            alt=""
                            style={{ width: "120px", height: "120px" }}
                          />
                          <div className="submittext">
                            {labels?.trackStatusAndReminder ??
                              "Track Statuses and Reminders"}
                          </div>
                          <div className="submitsubtext">
                            {labels?.trackDetails ??
                              "Track your progress and receive deadline reminders."}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="submit-benefit">
                          <img
                            src={submit}
                            alt=""
                            style={{ width: "120px", height: "120px" }}
                          />
                          <div className="submittext">
                            {labels?.submitDocAndRenew ??
                              "Submit Documents and Renewals"}
                          </div>
                          <div className="submitsubtext">
                            {labels?.submitDetails ??
                              "Keep up with required reporting and report changes online."}
                          </div>
                        </div>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{ backgroundColor: "#E6ECF1" }}>
              <Grid item xs={12}>
                <div className="manage-benefit">
                  {labels?.supportAndBenefits ??
                    "I Want to Explore Support and Benefits."}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={4}>
                    <Paper className="learnto">
                      <Grid container>
                        <Grid item xs={3}>
                          <img
                            src={call}
                            alt=""
                            style={{ width: "80px", height: "80px" }}
                          />
                        </Grid>
                        <Grid item xs={9} className="bottomlearnbtn">
                          <div className="textlearn">
                            {labels?.benefitCal ??
                              "How can I learn to use BenefitsCal?"}
                          </div>
                          <div className="buttonlearn">
                            {labels?.videosOrBlogs ??
                              "Watch How to Videos or Blogs"}
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper className="learnto">
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <img
                            src={location}
                            alt=""
                            style={{ width: "80px", height: "80px" }}
                          />
                        </Grid>
                        <Grid item xs={9} className="bottomlearnbtn">
                          <div className="textlearn">
                            {labels?.contactAndLocation ??
                              "Find My County Contact and location"}
                          </div>
                          <div className="buttonlearn">
                            {labels?.anOffice ?? "Find an Offices"}
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper className="learnto">
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <img
                            src={online}
                            alt=""
                            style={{ width: "80px", height: "80px" }}
                          />
                        </Grid>
                        <Grid item xs={9} className="bottomlearnbtn">
                          <div className="textlearn">
                            {labels?.onlineBenefit ??
                              "Learn Online Benefit Management here."}
                          </div>
                          <div className="buttonlearn">
                            {labels?.videosOrBlogs ??
                              "Watch How to Videos or Blogs"}
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default LandingPage;
