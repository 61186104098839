import React, { useEffect, useMemo, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import { useFieldArray, useForm } from "react-hook-form";
import CoTextField from "../../../controllers/CoTextField";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import usePageNav from "../../../hooks/usePageNav";
import usePageApi from "../../../hooks/usePageApi";
import useLoader from "../../../hooks/useLoader";
import { showErrorToast } from "../../../utils/services/toast";
import { isEmpty, map } from "lodash";
import useLookup from "../../../hooks/useLookup";
import UploadFile from "../../../components/FileUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { maxLength, name, onlyAlphabet } from "../../../utils/normalizers";
import { formatCurrency, number } from "../../../utils/normalizers";
import * as yup from "yup";
import { mandatory, onlyAlphabtes } from "../../../ValidationRules";
import { yupResolver } from "@hookform/resolvers/yup";
import useDynamicNav from "../../../hooks/useDynamicNav";

function Employment({ screenId }) {
  const [showLoader, hideLoader] = useLoader();
  const [openDocModal, setOpenDocModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [idData, setIdData] = useState([]);
  const { handleNext } = useDynamicNav({ screenId });
  const { navigations } = usePageNav();
  const { pageData, labels, saveJson } = usePageApi({
    screenId,
    callRetrieveStatus: true,
  });
  const { pageData: memberPageDate } = usePageApi({
    screenId: 206,
    callRetrieveStatus: true,
  });

  const userSchema = yup.object().shape({
    employer: yup.array().of(
      yup.object().shape({
        hhmember: yup.string().required("Please choose a household member"),
        employername: yup
          .string()
          .max(20, "Employer Name must be at most 20 characters")
          .required("Employer Name is required"),
        frequency: yup.string().required("Frequency is required"),
        wages: yup.string().required("Wages are required"),
        income: yup.array().of(
          yup.object().shape({
            hours: yup.string().required("Hours are required"),
            amount: yup.string().required("Amount is required"),
          })
        ),
      })
    ),
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userSchema),
    defaultValues: {
      employer: [],
    },
  });

  const { getLkpMatch, getOptions } = useLookup("Employment");

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "employer",
    }
  );

  const { frequency, incomeOptions } = useMemo(() => {
    return {
      frequency: getOptions("Frequency"),
      incomeOptions: getOptions("Income"),
    };
  }, [getOptions]);

  useEffect(() => {
    if (fields.length === 0) {
      append({ hhmember: "", employername: "", wages: "", frequency: "" });
    }
  }, []);

  const handleInsertField = (index) => {
    append({ hhmember: "", employername: "", wages: "", frequency: "" });
  };

  const memberList = () => {
    const data = map(memberPageDate, (value) => {
      return {
        label: `${value?.clientContainer?.client?.personName?.firstName} ${value?.clientContainer?.client?.personName?.lastName}`,
        value: value?.individualId,
      };
    });
    return data;
  };

  useEffect(() => {
    const data = memberList();
    setMembers(data);
  }, [memberPageDate]);

  const onSave = async (values) => {
    try {
      showLoader();
      const res = await saveJson(values);
      if ((res.status = 200)) {
        navigations.toSelfEmployement();
        handleNext();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (isEmpty(pageData)) {
    } else {
      reset(pageData);
    }
  }, [pageData]);

  const selectedFrequency = watch("employer");

  const onBack = () => {
    navigations.toIncomInfo();
  };

  const handeChange = async (event) => {
    let { value } = event.target;
    setIdData(value);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const obj = {
      AC: 2,
      AN: 1,
      BM: 1,
      BW: 5,
      MO: 1,
      OT: 1,
      QU: 1,
      SA: 1,
      WE: 5,
    };
    const fieldName = {
      AC: "Pay",
      AN: "Annual Income",
      BM: "Bi-Monthly Income",
      BW: "Week Income",
      MO: "Monthly Income",
      OT: "One Time Only",
      QU: "Quarterly - Every 3 Months",
      SA: "Semi-Annually - Twice A Year",
      WE: "Weekly",
    };
    let loopCount = obj[value];
    const fields = Array.from({ length: loopCount }, (_, index) => ({
      type: fieldName[value],
      amount: "",
      hours: "",
    }));
    setValue(`employer.${index}.income`, fields);
  };

  return (
    <Box sx={{ paddingLeft: "1rem" }}>
      <PageContainer
        title="Employment"
        onNext={handleSubmit(onSave)}
        onBack={onBack}
      >
        <Typography variant="h4">
          List anyone in your household who is employed now, or has been in the
          last 30 days.
        </Typography>

        {selectedFrequency.map((field, index) => (
          <Box key={field.id}>
            <Grid container>
              <Grid sm={12} lg={5.15}>
                <Typography variant="h4" my={2}>
                  Employer Details {index + 1}
                </Typography>
              </Grid>
              <Grid
                sm={12}
                lg={5.15}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<UploadFileIcon />}
                  onClick={() => setOpenDocModal(true)}
                >
                  Upload files
                </Button>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item sm={12} lg={5.15}>
                <Field
                  name={`employer.${index}.hhmember`}
                  label="HouseHold Member"
                  required
                  error={errors?.employer?.[index]?.hhmember?.message}
                  showError={errors?.employer?.[index]?.hhmember?.message}
                >
                  <Dropdown
                    name={`employer.${index}.hhmember`}
                    control={control}
                    options={members}
                    onChangeCustom={handeChange}
                  />
                </Field>
              </Grid>
              <Grid item sm={12} lg={5.15}>
                <Field
                  name={`employer.${index}.employername`}
                  error={errors?.employer?.[index]?.employername?.message}
                  showError={errors?.employer?.[index]?.employername?.message}
                  required
                  label="Employer Name"
                >
                  <CoTextField
                    normalizers={[onlyAlphabet, name, maxLength(20)]}
                    name={`employer.${index}.employername`}
                    control={control}
                    placeholder="Enter Employer Name"
                  />
                </Field>
              </Grid>
              <Grid item sm={12} lg={5.15}>
                <Field
                  name={`employer.${index}.frequency`}
                  label="Frequency"
                  required
                  error={errors?.employer?.[index]?.frequency?.message}
                  showError={errors?.employer?.[index]?.frequency?.message}
                >
                  <Dropdown
                    name={`employer.${index}.frequency`}
                    control={control}
                    options={frequency}
                    onChangeCustom={(e) => handleChange(e, index)}
                  />
                </Field>
              </Grid>
              <Grid item sm={12} lg={5.15}>
                <Field
                  name={`employer.${index}.wages`}
                  error={errors?.employer?.[index]?.wages?.message}
                  showError={errors?.employer?.[index]?.wages?.message}
                  required
                  label="How much are James's wages/tips (before tax)?"
                >
                  <CoTextField
                    //   normalizers={[onlyAlphabet, name, maxLength(20)]}
                    name={`employer.${index}.wages`}
                    control={control}
                    placeholder="Enter Amount"
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
            </Grid>

            {field?.income &&
              field?.income.map((data, idx) => (
                <>
                  <Grid container xs={6} sm={12} lg={12} mt={2} mb={1}>
                    <Typography variant="h4" alignItems="center">
                      {data?.type === "Pay" ||
                      data?.type === "Week Income" ||
                      data?.type === "Weekly"
                        ? `${data?.type} ${idx + 1}`
                        : data?.type}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    lg={12}
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item xs={6} sm={12} lg={5.15}>
                      <Field
                        name={`employer.${index}.income.${idx}.hours`}
                        error={
                          errors?.employer?.[index]?.income?.[idx]?.hours
                            ?.message
                        }
                        showError={
                          errors?.employer?.[index]?.income?.[idx]?.hours
                            ?.message
                        }
                        required
                        label="Hours"
                      >
                        <CoTextField
                          normalizers={[number, maxLength(2)]}
                          name={`employer.${index}.income.${idx}.hours`}
                          control={control}
                          placeholder="Enter Hours"
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={6} sm={12} lg={5.15}>
                      <Field
                        name={`employer.${index}.income.${idx}.amount`}
                        error={
                          errors?.employer?.[index]?.income?.[idx]?.amount
                            ?.message
                        }
                        showError={
                          errors?.employer?.[index]?.income?.[idx]?.amount
                            ?.message
                        }
                        required
                        label="Amount"
                      >
                        <CoTextField
                          //   normalizers={[onlyAlphabet, name, maxLength(20)]}
                          name={`employer.${index}.income.${idx}.amount`}
                          control={control}
                          placeholder="Enter Amount"
                          normalizers={[formatCurrency]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {" "}
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </>
              ))}

            <Grid container mt={1}>
              <Grid sm={6} lg={5.15}>
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => handleInsertField(fields.length)}
                >
                  Add Employer
                </Button>
              </Grid>
              {index === 0 ? (
                ""
              ) : (
                <Grid
                  sm={6}
                  lg={5.15}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    sx={{
                      color: "var(--grey-400)",
                      borderColor: "var(--grey-400)",
                    }}
                    variant="outlined"
                    startIcon={<RemoveCircleOutlineIcon />}
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        ))}
        <UploadFile
          docOpen={openDocModal}
          setDocOpen={setOpenDocModal}
          defaultDocType={"IN"}
          subTypeOptions={incomeOptions}
          activeIndex={idData}
        />
      </PageContainer>
    </Box>
  );
}

export default Employment;
