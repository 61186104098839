import React, { useEffect, useMemo, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import { useFieldArray, useForm } from "react-hook-form";
import CoTextField from "../../../controllers/CoTextField";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { showErrorToast } from "../../../utils/services/toast";
import usePageApi from "../../../hooks/usePageApi";
import { isEmpty, map } from "lodash";
import useLookup from "../../../hooks/useLookup";
import usePageNav from "../../../hooks/usePageNav";
import useLoader from "../../../hooks/useLoader";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadFile from "../../../components/FileUpload";
import { formatCurrency, maxLength, number } from "../../../utils/normalizers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useDynamicNav from "../../../hooks/useDynamicNav";

function AdditionalIncome({ screenId }) {
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [openDocModal, setOpenDocModal] = useState(false);
  const [members, setMembers] = useState([]);
  const { handleNext } = useDynamicNav({ screenId });
  const [idData, setIdData] = useState([]);
  const { pageData: memberPageDate } = usePageApi({
    screenId: 206,
    callRetrieveStatus: true,
  });

  const userSchema = yup.object().shape({
    employer: yup.array().of(
      yup.object().shape({
        hhmember: yup.string().required("Please choose a household member"),
        incometype: yup.string().required("Please choose a Income Type"),
        frequency: yup.string().required("Frequency is required"),
        amount: yup.string().required("Amount are required"),
        income: yup.array().of(
          yup.object().shape({
            hours: yup.string().required("Hours are required"),
            amount: yup.string().required("Amount is required"),
          })
        ),
      })
    ),
  });

  const {
    control,
    formState: { errors, isValid },
    trigger,
    register,
    watch,
    getValues,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userSchema),
    defaultValues: {
      employer: [],
    },
  });
  const { getOptions } = useLookup("AdditionalIncome");

  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId,
    callRetrieveStatus: true,
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "employer",
    }
  );
  const selectedFrequency = watch("employer");

  useEffect(() => {
    if (fields.length === 0) {
      append({ hhmember: "", incometype: "", amount: "", frequency: "" });
    }
  }, []);

  const { incomeType, frequency } = useMemo(() => {
    return {
      incomeType: getOptions("Income Type"),
      frequency: getOptions("Frequency"),
    };
  }, [getOptions]);

  const handleInsertField = (index) => {
    // insert(index, { value: "" });
    append({ hhmember: "", incometype: "", amount: "", frequency: "" });
  };

  useEffect(() => {
    if (isEmpty(pageData)) {
    } else {
      reset(pageData);
    }
  }, [pageData]);

  const onSave = async (values) => {
    try {
      showLoader();
      const res = await saveJson(values);
      if (res?.status === 200) {
        navigations.toWicProgram();
        handleNext();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const onBack = () => {
    navigations.toSelfEmployement();
  };

  const handeChange = async (event) => {
    let { value } = event.target;
    setIdData(value);
  };

  console.log(memberPageDate, 88888);
  const memberList = () => {
    const data = map(memberPageDate, (value) => {
      return {
        label: `${value?.clientContainer?.client?.personName?.firstName} ${value?.clientContainer?.client?.personName?.lastName}`,
        value: value?.individualId,
      };
    });
    return data;
  };

  useEffect(() => {
    const data = memberList();
    setMembers(data);
  }, [memberPageDate]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const obj = {
      AC: 2,
      AN: 1,
      BM: 1,
      BW: 5,
      MO: 1,
      OT: 1,
      QU: 1,
      SA: 1,
      WE: 5,
    };
    const fieldName = {
      AC: "Pay",
      AN: "Annual Income",
      BM: "Bi-Monthly Income",
      BW: "Week Income",
      MO: "Monthly Income",
      OT: "One Time Only",
      QU: "Quarterly - Every 3 Months",
      SA: "Semi-Annually - Twice A Year",
      WE: "Weekly",
    };
    let loopCount = obj[value];
    const fields = Array.from({ length: loopCount }, (_, index) => ({
      type: fieldName[value],
      amount: "",
      hours: "",
    }));
    setValue(`employer.${index}.income`, fields);
  };

  console.log(members, 89899);
  return (
    <Box sx={{ paddingLeft: "1rem" }}>
      <PageContainer
        title="Additional Income"
        onNext={handleSubmit(onSave)}
        onBack={onBack}
      >
        <Typography variant="h4">
          List anyone in your household who has additional income.
        </Typography>

        {selectedFrequency.map((field, index) => (
          <Box key={field.id}>
            <Grid container>
              <Grid sm={12} lg={5.15}>
                <Typography variant="h4" my={2}>
                  Employer Details {index + 1}
                </Typography>
              </Grid>
              <Grid
                sm={12}
                lg={5.15}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<UploadFileIcon />}
                  onClick={() => setOpenDocModal(true)}
                >
                  Upload files
                </Button>
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2}>
              <Grid item sm={12} lg={5.15}>
                <Field
                  name={`employer.${index}.hhmember`}
                  error={errors?.employer?.[index]?.hhmember?.message}
                  showError={errors?.employer?.[index]?.hhmember?.message}
                  label="HouseHold Member"
                  required
                >
                  <Dropdown
                    name={`employer.${index}.hhmember`}
                    control={control}
                    options={members}
                    onChangeCustom={handeChange}
                  />
                </Field>
              </Grid>
              <Grid item sm={12} lg={5.15}>
                <Field
                  name={`employer.${index}.incometype`}
                  error={errors?.employer?.[index]?.incometype?.message}
                  showError={errors?.employer?.[index]?.incometype?.message}
                  required
                  label="Income Type"
                >
                  <Dropdown
                    name={`employer.${index}.incometype`}
                    control={control}
                    options={incomeType}
                  />
                </Field>
              </Grid>

              <Grid item sm={12} lg={5.15}>
                <Field
                  label="Frequency"
                  name={`employer.${index}.frequency`}
                  error={errors?.employer?.[index]?.frequency?.message}
                  showError={errors?.employer?.[index]?.frequency?.message}
                  required
                >
                  <Dropdown
                    name={`employer.${index}.frequency`}
                    control={control}
                    options={frequency}
                    onChangeCustom={(e) => handleChange(e, index)}
                  />
                </Field>
              </Grid>
              <Grid item sm={12} lg={5.15}>
                <Field
                  name={`employer.${index}.amount`}
                  error={errors?.employer?.[index]?.amount?.message}
                  showError={errors?.employer?.[index]?.amount?.message}
                  required
                  label="Amount (Dollars)"
                >
                  <CoTextField
                    //   normalizers={[onlyAlphabet, name, maxLength(20)]}
                    name={`employer.${index}.amount`}
                    control={control}
                    placeholder="Enter Amount"
                    normalizers={[formatCurrency]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                </Field>
              </Grid>
              <Grid item sm={12} lg={12}>
                {field?.income &&
                  field?.income.map((data, idx) => (
                    <>
                      <Grid container xs={6} sm={12} lg={12} mt={2} mb={1}>
                        <Typography variant="h4" alignItems="center">
                          {data?.type === "Pay" ||
                          data?.type === "Week Income" ||
                          data?.type === "Weekly"
                            ? `${data?.type} ${idx + 1}`
                            : data?.type}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        lg={12}
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid item xs={6} sm={12} lg={5.15}>
                          <Field
                            name={`employer.${index}.income.${idx}.hours`}
                            error={
                              errors?.employer?.[index]?.income?.[idx]?.hours
                                ?.message
                            }
                            showError={
                              errors?.employer?.[index]?.income?.[idx]?.hours
                                ?.message
                            }
                            required
                            label="Hours"
                          >
                            <CoTextField
                              normalizers={[number, maxLength(2)]}
                              name={`employer.${index}.income.${idx}.hours`}
                              control={control}
                              placeholder="Enter Hours"
                              // normalizers={[formatCurrency]}
                            />
                          </Field>
                        </Grid>
                        <Grid item xs={6} sm={12} lg={5.15}>
                          <Field
                            name={`employer.${index}.income.${idx}.amount`}
                            error={
                              errors?.employer?.[index]?.income?.[idx]?.amount
                                ?.message
                            }
                            showError={
                              errors?.employer?.[index]?.income?.[idx]?.amount
                                ?.message
                            }
                            required
                            label="Amount"
                          >
                            <CoTextField
                              //   normalizers={[onlyAlphabet, name, maxLength(20)]}
                              name={`employer.${index}.income.${idx}.amount`}
                              control={control}
                              placeholder="Enter Amount"
                              normalizers={[formatCurrency]}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Field>
                        </Grid>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </Grid>
            <Stack
              margin={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => handleInsertField(fields.length)}
              >
                Add Additional Income
              </Button>

              {index === 0 ? (
                ""
              ) : (
                <Button
                  sx={{
                    color: "var(--grey-400)",
                    borderColor: "var(--grey-400)",
                  }}
                  variant="outlined"
                  startIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => remove(index)}
                >
                  Remove
                </Button>
              )}
            </Stack>
          </Box>
        ))}
      </PageContainer>
      <UploadFile
        docOpen={openDocModal}
        setDocOpen={setOpenDocModal}
        defaultDocType={"IN"}
        // subTypeOptions={incomeOptions}
        activeIndex={idData}
      />
    </Box>
  );
}

export default AdditionalIncome;
