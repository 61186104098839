import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Grid, IconButton, Tooltip } from "@mui/material";

function EarnedInc({ labels, summaryData }) {
  const employers = summaryData?.inEmployment?.employer || [];
  const columns = useMemo(
    () => [
      {
        field: "employername",
        headerName: labels?.employerName ?? "Employer Name",
        renderCell: (employername) => employername ?? "-",
      },
      {
        field: "wages",
        headerName: labels?.wages ?? "Wages",
        renderCell: (wages) => wages ?? "-",
      },
      {
        field: "frequency",
        headerName: labels?.frequency ?? "Frequency",
        renderCell: (frequency) => frequency ?? "-",
      },
      {
        headerName: labels?.incomeType ?? "Income Type",
        renderCell: (row) =>
          row.income.map((income, index) => (
            <div key={index}>
              {income.type ?? "-"} {income.amount ?? "-"} ({income.hours ?? "-"}{" "}
              )
            </div>
          )),
      },
      {
        headerName: labels?.Update ?? "Update",
        renderCell: () => (
          <Tooltip title="Update">
            <IconButton>
              <RestoreRoundedIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [labels]
  );

  return (
    <SummaryContent title={labels?.eaInc ?? "Earned Income"}>
      <TabularView
        sx={{
          table: {
            width: "100%",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0 1.25rem 0.5rem 0",
          },
        }}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        columns={columns}
        data={employers}
      />
    </SummaryContent>
  );
}

EarnedInc.propTypes = {};

export default EarnedInc;
