import React from "react";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function CDatePicker({
  control,
  name,
  sx,
  disableFuture,
  minDate,
  disablePast,
  maxDate,
}) {
  console.log(minDate, "dsdsd");
  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            sx={sx}
            value={field.value ? dayjs(field.value || "") : null}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
            disablePast={disablePast}
          />
        )}
      />
    </div>
  );
}
