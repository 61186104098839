import React, { useState } from "react";
import { Box, Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import Field from "../../components/Field";
import Dropdown from "../../components/Dropdown";
import { useForm } from "react-hook-form";
import SignaturePad from "../../components/SignaturePad";
import { getSignSave, getSubmitCase } from "../../services/apiRequests";
import { selecttActiveContId } from "../../store/selectors/appSelector";
import { useSelector } from "react-redux";
import useLoader from "../../hooks/useLoader";
import usePageNav from "../../hooks/usePageNav";
import dayjs from "dayjs";
import CDatePicker from "../../components/DatePicker";
import useDynamicNav from "../../hooks/useDynamicNav";

function Signature({ screenId }) {
  const [signOpen, setSignOpen] = useState(false);
  const [sign, setSign] = useState("");
  const [open, setOpen] = useState(false);
  const { navigations } = usePageNav();
  const controlId = useSelector(selecttActiveContId);
  const [showLoader, hideLoader] = useLoader();
  const { handleNext } = useDynamicNav({ screenId });

  const toggleSignClose = (data) => {
    setSignOpen(false);
    if (data) {
      setSign(data);
      setValue("signatureImage", data);
    }
  };

  const toggleClose = () => {
    setOpen(false);
    setSignOpen(false);
  };

  const onSubmit = () => {
    setOpen(true);
  };

  const {
    control,
    formState: { errors, isValid },
    trigger,
    register,
    watch,
    getValues,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const caseSubmited = async () => {
    // {
    //   controlId: controlId,
    //   signatureImage: sign
    //   signatoryName: getValues("signatoryName")
    // }
    const payload = [
      {
        individualId: null,
        dirtyStatus: "",
        importedFromWp: "",
        clearanceStatus: "",
        controlId: controlId,
        importSelected: true,
        editable: true,
        signatureDetails: {
          signatureType: "1",
          ldssCd: "",
          scheduleInterview: {
            districtOffice: null,
          },
          interviewSignature: {
            dirtyStatus: "INSERT",
            appointmentStartTime: dayjs().format("YYYY-MM-DD"),
            appointmentEndTime: null,
            appointmentType: "",
            signatures: [
              {
                signatureImage: sign,
                signatoryType: "HM",
                signatoryName: getValues("signatoryName"),
                signatureId: 0,
                paperApplicationAvailableYN: "",
                signedDt: dayjs().format("YYYY-MM-DD"),
              },
            ],
          },
        },
      },
    ];

    // console.log(payload);
    try {
      showLoader();
      const res = await getSignSave(controlId, payload);
      if (res.status === 200) {
        setOpen(false);
        handleNext();
        caseSub();
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
  };

  const caseSub = async () => {
    try {
      showLoader();
      const res = await getSubmitCase(controlId);
      if (res.status === 200) {
        navigations.toDashboard();
        // pdfGet();
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <Box sx={{ paddingLeft: "1rem" }}>
      <Typography variant="h3" sx={{ color: "var(--grey-400)", mb: "0.5rem" }}>
        Your Signature
      </Typography>
      <Typography variant="h5" color="var(--grey-400)">
        Signing the agreement for this application does not replace signature
        requirements as part of the enrollment process for the WIC program. A
        WIC staff member will contact you to schedule your first appointment.
        The WIC Program is not administered through your MDHHS Caseworker. This
        institution is an equal opportunity provider. View the full
        Non-Discrimination Statement here.
      </Typography>

      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        alignItems="center"
      >
        <Grid item xs={6} my={2}>
          <Field
            name="DOS"
            label="Date of Signature"
            error={errors.DOS?.message}
            showError={errors.DOS?.message}
            required
          >
            <CDatePicker
              name="DOS"
              minDate={dayjs().subtract(4, "days")}
              maxDate={dayjs().add(4, "days")}
              control={control}
              placeholder="Select Date(MM/DD/YYYY)"
            />
          </Field>
        </Grid>
        <Grid item my={3}>
          <Button variant="outlined" onClick={() => setSignOpen(true)}>
            Signature
          </Button>
        </Grid>
        {sign && (
          <Grid item bgcolor="white">
            <img width={170} height={62} alt="sign" src={sign} />
          </Grid>
        )}
      </Stack>
      <SignaturePad open={signOpen} onClose={toggleSignClose} />
      <Grid mt={2}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </Grid>
      <Dialog open={open} onClose={toggleClose}>
        <Typography padding={1} variant="h3">
          Do you confirm?
        </Typography>
        <Grid padding={2}>
          <Typography variant="h5">
            In order to expedite the processing of your application, please
            check if you have submitted all of the required documents related to
            Income, Expenses and Resources/Assets.
          </Typography>
          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            <Button variant="outlined" onClick={toggleClose}>
              Discard
            </Button>
            <Button variant="contained" color="success" onClick={caseSubmited}>
              Submit
            </Button>
          </Stack>
        </Grid>
      </Dialog>
    </Box>
  );
}

export default Signature;
