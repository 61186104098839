import { Grid, Stack, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
// import usePageApi from "../../../../hooks/usePageApi";
import { filter } from "lodash";
import dayjs from "dayjs";
// import ActionButtons from "../../../../components/ActionButtons";
import Divider from "@mui/material/Divider";
import useLookup from "../../../../hooks/useLookup";
import usePageNav from "../../../../hooks/usePageNav";

export default function MemberDetails({ selected, pageData, labels }) {
  const { individualId } = selected;
  // console.log(id);
  const { getLkpMatch } = useLookup("memberSummary");
  const { navigations } = usePageNav();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData, selected]);

  const memberData = filter(
    data,
    (item) => item?.individualId === individualId
  );
  // console.log(memberData, 7878);
  const {
    personName,
    gender,
    dob,
    race,
    maritalStatus,
    individualDetails,
    blindYN,
    deafYN,
    disabledYN,
    soSec,
  } = memberData?.[0]?.clientContainer?.client ?? {};
  const { contactInformation } = memberData?.[0]?.clientContainer ?? {};
  const { firstName, lastName } = personName ?? {};
  const { homeAddress, email } = contactInformation ?? {};
  const { addressLine1, city, state, zipcode } = homeAddress ?? {};
  const { primaryRace } = race ?? {};
  const { marriedYN } = maritalStatus ?? {};
  const { ethnicity } = individualDetails ?? {};
  const { soSecPart1, soSecPart2, soSecPart3 } = soSec ?? {};
  return (
    <>
      {/* <Divider /> */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={3}
          sx={{ flexDirection: "column", gap: "1rem", display: "flex" }}
        >
          <Stack xs={8}>
            <Typography variant="h4grey">{labels?.name ?? "Name"}</Typography>
          </Stack>

          <Stack xs={8}>
            <Typography variant="h4grey">{labels?.dob ?? "DOB"}</Typography>
          </Stack>

          <Stack xs={8}>
            <Typography variant="h4grey">{labels?.race ?? "Race"}</Typography>
          </Stack>
          <Stack xs={8}>
            <Typography variant="h4grey">
              {labels?.ethinicity ?? "Ethnicity"}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Stack xs={8}>
            <Typography>
              {firstName} &nbsp; {lastName}
            </Typography>
          </Stack>

          <Stack xs={8}>
            <Typography>{dayjs(dob).format("MM-DD-YYYY") ?? "-"}</Typography>
          </Stack>

          <Stack xs={8}>
            <Typography>{getLkpMatch("Race", primaryRace) ?? "-"}</Typography>
          </Stack>
          <Stack xs={8}>
            <Typography>
              {getLkpMatch("Ethnicity", ethnicity) ?? "-"}
            </Typography>
          </Stack>
        </Grid>

        {/* <Grid item xs={8} mt="2rem">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">{labels?.name ?? "Name"}</Typography>
            <Typography>
              {firstName} &nbsp; {lastName}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">
              {labels?.gender ?? "Gender"}
            </Typography>
            <Typography>{getLkpMatch("Gender", gender) ?? "-"}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">{labels?.ssn ?? "SSN"}</Typography>
            {soSecPart1 && soSecPart2 && soSecPart3
              ? `*** ** ${soSecPart3}`
              : "-"}
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">{labels?.dob ?? "DOB"}</Typography>
            <Typography>{dayjs(dob).format("MM-DD-YYYY") ?? "-"}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">
              {labels?.martialStatus ?? "Martial Status"}
            </Typography>
            <Typography>
              {getLkpMatch("MartialStatus", marriedYN) ?? "-"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">{labels?.race ?? "Race"}</Typography>
            <Typography>{getLkpMatch("Race", primaryRace) ?? "-"}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">
              {labels?.ethinicity ?? "Ethnicity"}
            </Typography>
            <Typography>
              {getLkpMatch("Ethnicity", ethnicity) ?? "-"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">
              {labels?.disability ?? "Disability"}
            </Typography>
            <Typography>
              {getLkpMatch("YesNo", blindYN)}&nbsp;
              {getLkpMatch("YesNo", deafYN)}&nbsp;
              {getLkpMatch("YesNo", disabledYN)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">
              {labels?.address ?? "Address"}
            </Typography>
            <Typography>
              {addressLine1 ?? "-"}&nbsp;{city ?? "-"}&nbsp;
              {getLkpMatch("State", state) ?? "-"}&nbsp;{zipcode ?? "-"}
            </Typography>
          </Stack>
        </Grid> */}

        {/* <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">{labels?.phone ?? "Phone"}</Typography>
            <Typography>
              {memberData?.[0]?.clientContainer?.contactInformation?.phoneNumber
                ?.phoneNumber ?? " -"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4grey">{labels?.email ?? "Email"}</Typography>
            <Typography>{email ?? "-"}</Typography>
          </Stack>
        </Grid> */}
      </Grid>
    </>
  );
}
