import React, { useEffect } from "react";
import PageContainer from "../../components/PageContainer";
import { Box, Paper, Stack, Typography, IconButton } from "@mui/material";
import { ReactComponent as Businesscenter } from "../../assets/images/business_center.svg";
import { ReactComponent as Desktop } from "../../assets/images/desktop.svg";
import { ReactComponent as Payment } from "../../assets/images/payments.svg";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../components/YesNoOption";
import { showErrorToast } from "../../utils/services/toast";
import usePageApi from "../../hooks/usePageApi";
import usePageNav from "../../hooks/usePageNav";
import useLoader from "../../hooks/useLoader";
import useDynamicNav from "../../hooks/useDynamicNav";

function Income({ screenId }) {
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const onBack = () => {
    navigations.toFamilySurvey();
  };
  const { handleNext } = useDynamicNav({ screenId });

  const onNext = () => {
    // handleNext();
    // navigations.toRelationShip();
  };

  const {
    watch,
    control,
    getValues,
    setValue,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(userShema),
    defaultValues: {
      isEnrolledSelfEmp: "",
      isEnrolledEmployment: "",
      isEnrolledAdditional: "",
      //   ...pageData,
    },
  });

  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId,
    callRetrieveStatus: true,
  });

  const onSave = async (value) => {
    try {
      showLoader();
      const res = await saveJson(value);
      if (res?.status === 200) {
        navigations.toEmployement();
        handleNext();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    reset({
      isEnrolledSelfEmp: pageData?.isEnrolledSelfEmp ?? "",
      isEnrolledEmployment: pageData?.isEnrolledEmployment ?? "",
      isEnrolledAdditional: pageData?.isEnrolledAdditional ?? "",
    });
  }, [pageData]);

  return (
    <PageContainer title="Income" onBack={onBack} onNext={handleSubmit(onSave)}>
      <Paper>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "2rem",
            alignItems: "center",
          }}
        >
          <Box>
            <Stack direction="row" spacing={1}>
              <Box>
                <IconButton>
                  <Businesscenter />
                </IconButton>
              </Box>

              <Stack direction="column" spacing={1}>
                <Typography variant="h4" color="var(--grey-400)">
                  Employment
                </Typography>
                <Typography variant="h4">
                  Is anyone in your household employed now or in the last
                  30days?
                </Typography>
                <Typography variant="h6">
                  This includes temporary and contract jobs.
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Box>
            <Controller
              name="isEnrolledEmployment"
              control={control}
              render={({ field }) => {
                return <YesNoOption {...field} />;
              }}
            />
          </Box>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "2rem",
            alignItems: "center",
          }}
        >
          <Box>
            <Stack direction="row" spacing={1}>
              <Box>
                <IconButton>
                  <Desktop />
                </IconButton>
              </Box>

              <Stack direction="column" spacing={1}>
                <Typography variant="h4" color="var(--grey-400)">
                  Self-Employment
                </Typography>
                <Typography variant="h4">
                  Is anyone in your household self-employed?
                </Typography>
                <Typography variant="h6">This includes odd jobs.</Typography>
              </Stack>
            </Stack>
          </Box>

          <Box>
            <Controller
              name="isEnrolledSelfEmp"
              control={control}
              render={({ field }) => {
                return <YesNoOption {...field} />;
              }}
            />
          </Box>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "2rem",
            alignItems: "center",
          }}
        >
          <Box>
            <Stack direction="row" spacing={1}>
              <Box>
                <IconButton>
                  <Payment />
                </IconButton>
              </Box>

              <Stack direction="column" spacing={1}>
                <Typography variant="h4" color="var(--grey-400)">
                  Additional Income
                </Typography>
                <Typography variant="h4">
                  Does anyone in your household have additional income?
                </Typography>
                <Typography variant="h6">
                  Includes unemployment, disability (SSI), alimony, workers’
                  compensation, child support, social security
                  (RSDI),pension/retirement, veterans benefits/military
                  allotments,foster care/adoption subsidy, refugee
                  resettlement/match grant, tribal income/benefits, short/long
                  term disability,etc.
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Box>
            <Controller
              name="isEnrolledAdditional"
              control={control}
              render={({ field }) => {
                return <YesNoOption {...field} />;
              }}
            />
          </Box>
        </Stack>
      </Paper>
    </PageContainer>
  );
}

export default Income;
