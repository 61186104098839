import React, { useEffect, useMemo } from "react";
import ScreenProvider from "../../../containers/ScreenProvider";
import usePageApi from "../../../hooks/usePageApi";
import PageContainer from "../../../components/PageContainer";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Field from "../../../components/Field";
import CRadioButton from "../../../components/RadioButton";
import useLookup from "../../../hooks/useLookup";
import useLoader from "../../../hooks/useLoader";
import usePageNav from "../../../hooks/usePageNav";
import CCheckBox from "../../../components/checkBox";
import useDynamicNav from "../../../hooks/useDynamicNav";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import { useSelector } from "react-redux";
import { mandatory } from "../../../ValidationRules";

const RequestedProgram = ({ screenId }) => {
  const { getOptions } = useLookup("hoh");
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const [showLoader, hideLoader] = useLoader();
  const controlId = useSelector(selecttActiveContId);
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId,
    // screenDefn: "/application/memberInfo/requested-program",
    callRetrieveStatus: true,
  });

  const { gender } = useMemo(() => {
    return {
      gender: getOptions("Gender"),
    };
  }, [getOptions]);

  const userShema = yup.object({
    programRequesting: yup
      .string()
      .required("Please Choose any Requesting Program"),
    gender: mandatory("Please Choose any Options"),
    areYou: yup.string().required("Please Choose any Requesting Program"),
  });

  const {
    watch,
    control,
    getValues,
    setValue,
    reset,
    //errors,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    // resolver: yupResolver(userShema),
    defaultValues: {
      program: null,
      naProgram: null,
      notBrestFeeding: null,
      brestFeeding: null,
      pregnent: null,
      otherhoh: null,
      gender: null,
      ...pageData,
    },
  });
  const onSave = async (value) => {
    const payload = {
      program: getValues("program") === true ? "Y" : "N",
      naProgram: getValues("naProgram") === true ? "Y" : "N",
      notBrestFeeding: getValues("notBrestFeeding") === true ? "Y" : "N",
      brestFeeding: getValues("brestFeeding") === true ? "Y" : "N",
      pregnent: getValues("pregnent") === true ? "Y" : "N",
      otherhoh: getValues("otherhoh") === true ? "Y" : "N",
      gender: getValues("gender"),
      dirtyStatus: "INSERT",
      screenDefn: "/application/memberInfo/requested-program",
      screenId: screenId,
    };
    try {
      showLoader();
      const res = await saveJson(payload);
      if (res.status === 200) {
        navigations.toHouseHoldMember();
        handleNext();
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (pageData) {
      reset({
        program: pageData?.program === "Y" ? true : false,
        naProgram: pageData?.naProgram === "Y" ? true : false,
        notBrestFeeding: pageData?.notBrestFeeding === "Y" ? true : false,
        brestFeeding: pageData?.brestFeeding === "Y" ? true : false,
        pregnent: pageData?.pregnent === "Y" ? true : false,
        otherhoh: pageData?.otherhoh === "Y" ? true : false,
        gender: pageData?.gender,
      });
    }
  }, [pageData, reset]);

  const onBack = () => {
    navigations.toCitizenShip();
  };
  return (
    <ScreenProvider labels={labels}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <PageContainer
          title="Let’s begin with your information"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Stack
            rowGap={2}
            sx={{
              minWidth: "15.6rem",
              maxWidth: "30rem",
              paddingTop: "0.5rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Field
                  name="programRequesting"
                  label="Select which programs you are requesting:"
                  error={errors.programRequesting?.message}
                  showError={errors.programRequesting?.message}
                >
                  <CCheckBox
                    name="program"
                    control={control}
                    label="Women, Infants, & Children (WIC)"
                  />
                  <CCheckBox
                    name="naProgram"
                    control={control}
                    label="Not Applying for Programs"
                  />
                </Field>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Field
                  name="areYou"
                  label="You are:"
                  error={errors.areYou?.message}
                  showError={errors.areYou?.message}
                >
                  <CCheckBox
                    name="pregnent"
                    control={control}
                    label="Pregnant"
                  />
                  <CCheckBox
                    name="brestFeeding"
                    control={control}
                    label="Mother Breastfeeding"
                  />
                  <CCheckBox
                    name="notBrestFeeding"
                    control={control}
                    label="Mother Not Breastfeeding"
                  />
                  <CCheckBox
                    name="otherhoh"
                    control={control}
                    label="Applying for other household memebers"
                  />
                </Field>
              </Grid>

              <Grid item sx={{ display: "flex", flexDirection: "column" }}>
                <Field
                  name="gender"
                  error={errors.gender?.message}
                  showError={errors.gender?.message}
                  label="Gender"
                >
                  <CRadioButton
                    row
                    name="gender"
                    control={control}
                    radioArray={gender}
                  />
                </Field>
              </Grid>
            </Grid>
          </Stack>
        </PageContainer>
      </Box>
    </ScreenProvider>
  );
};

export default RequestedProgram;
