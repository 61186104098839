import { all, takeEvery, put, takeLatest, select } from "redux-saga/effects";
import HttpClient, { authApi } from "../../utils/HttpClient";
import { AUTHENTICATE } from "../types/authTypes";
import { setAuthToken } from "../slices/authSlice";
import { showErrorToast } from "../../utils/services/toast";
import { find } from "lodash";
import { toast } from "react-toastify";
import { getAge } from "../../utils/helpers";
import { setHohInfo, setScreenStatuses } from "../slices/appSlice";
import { FETCH_HOH_INFO, STATUS_RETRIEVE } from "../types/appTypes";
import { selecttActiveContId } from "../selectors/appSelector";
import dayjs from "dayjs";

export function* fetchHohInfo(action) {
  try {
    const activeControlId = yield select(selecttActiveContId);
    const controlId = action.payload ?? activeControlId;
    const res = yield HttpClient.post("/public/consumer/jsonRetrieve", {
      screenId: 201,
      controlId,
    });
    if (res.data) {
      if (res.data === "No Json Doc found") {
        //
      } else {
        const membersList = res.data[0];
        // const hohDetails = find(membersList, { isHoh: "Y" });
        // const {
        //   firstName = "",
        //   lastName = "",
        //   dateOfBirth = "",
        //   individualId = "",
        // } = hohDetails ?? {};
        // const age = getAge(dateOfBirth);
        yield put(
          setHohInfo({
            firstName:
              membersList?.clientContainer?.client?.personName?.firstName,
            lastName:
              membersList?.clientContainer?.client?.personName?.lastName,
            dob: dayjs(membersList?.clientContainer?.client?.dob).format(
              "MM-DD-YYYY"
            ),
            age: getAge(membersList?.clientContainer?.client?.dob),
            individualId: membersList?.individualId,
          })
        );
      }
    }
  } catch (error) {
    // toast.warn("Unable to fetch HOH details");
    console.log("error in header");
  } finally {
    //
  }
}

export function* statusRetrieve(action) {
  try {
    const activeControlId = yield select(selecttActiveContId);
    const controlId = action.payload ?? activeControlId;
    const res = yield HttpClient.get(
      `/uiLable/module/statusRetrieve/${controlId}
    `
    );
    if (res.status === 200) {
      yield put(setScreenStatuses(res.data));
    }
  } catch (error) {
    // toast.warn("Unable to fetch HOH details");
    console.log("error in status");
  } finally {
    //
  }
}

export default function* watchApp() {
  yield all([
    takeEvery(FETCH_HOH_INFO, fetchHohInfo),
    takeEvery(STATUS_RETRIEVE, statusRetrieve),
  ]);
}
